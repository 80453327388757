import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomFileLike } from 'src/app/models/custom-file-like';
import { AcordoJuridicoService } from 'src/app/services/acordo-juridico.service';
import { ProcessosJudiciaisRecursoDesdobramentoService } from 'src/app/services/processos-judiciais-recurso-desdobramento.service';
import Swal from 'sweetalert2';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-modal-pagamento',
    templateUrl: './modal-pagamento.component.html',
    styleUrls: [
        './modal-pagamento.component.scss',
        "../../../../../vendor/styles/pages/dropzone.scss"
    ]
})
export class ModalPagamentoComponent implements OnInit {

    swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    private modalRef: NgbModalRef;

    public acordo: any;

    public parcela: any;    

    public tipoPagamento: number;

    public valorPagamentoDivida: number;

    public hasBaseDropZoneOver: boolean;

    public uploader: FileUploader;
    
    private files: Array<File> = [];

    public dividas: any;

    public ignorarSuccessFee: boolean = false;

    pt_calendar: any = {};

    public dataPagamento:Date = new Date();

    constructor(
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        private processosJudiciaisRecursoDesdobramentoService: ProcessosJudiciaisRecursoDesdobramentoService,
        private acordoJuridicoService: AcordoJuridicoService,
        private primeNgConfig: PrimeNGConfig,
    ) { 
        this.uploader = new FileUploader({
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise((resolve, reject) => { });
            },
        });
    }

    ngOnInit(): void {

        this.primeNgConfig.setTranslation({
            dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            today: 'Hoje',
            clear: 'Limpar'
        });

        this.pt_calendar = {
            firstDayOfWeek: 0,
            dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            today: 'Hoje',
            clear: 'Limpar'
        };

        this.buscarRecurso(this.acordo.processoJudicialRecursoDesdobramentoId);

        this.uploader.onAfterAddingFile = (file) => {                          
            this.files.push(new File([file._file], file.file.name, {type: file.file.type}));
  
            const fileInQueue: any = this.uploader.queue.find(
              (q) => q.file.name === file.file.name
            );
            const newFile = new CustomFileLike(fileInQueue.file);
            fileInQueue.file = newFile;
          };
    }

    buscarRecurso(recursoDesdobramentoDetalheId){   
        this.spinner.show();     
        this.processosJudiciaisRecursoDesdobramentoService.obterPorIdComDetalhes(recursoDesdobramentoDetalheId)
            .subscribe(res=>{                
                this.dividas = res.data.valores;
                console.log(this.dividas);
                this.spinner.hide();
            }, err=>{
                this.toastrService.error('Não foi possível buscar as Dívidas do Processo!', 'Atenção', { timeOut: 10000 });
            })
    }

    changeTipoPagamento(){

    }

    dismiss(){
        this.modalRef.dismiss();
    } 

    salvar(){
        console.log(this.parcela);
        if(this.check()){
            debugger;
            let valor = this.valorPagamentoDivida.toString().replace(".",",");
            let formData: FormData = new FormData();
            formData.append('acordoId', this.parcela.acordoId.toString());
            formData.append('parcelaId', this.parcela.id.toString());
            formData.append('valorPagamentoDivida', valor);
            formData.append('dataPagamento', this.dataPagamento.toISOString());
            formData.append('ignorarSuccessFee', this.ignorarSuccessFee.toString());

            this.files.forEach(file => {
                formData.append('files', file);
            });

            this.spinner.show();

            this.acordoJuridicoService.definirPagamentoParcela(formData)
                .subscribe(res=>{
                    this.spinner.hide();
                    this.modalRef.close();
                }, error => {
                    if (error && error.errors) {
                        this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                    }
                    else {
                        this.toastrService.error('Não foi possível salvar!', 'Atenção', { timeOut: 10000 });
                    }
                    this.spinner.hide();
                })
        }
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    onDrop(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            console.log(files[i]);
            debugger;
            this.files.push(files[i] as File);
        }

        const fileInQueue: any = this.uploader.queue.find(
            (q) => q.file.name === files[0].name
        );
        const newFile = new CustomFileLike(fileInQueue.file);
        fileInQueue.file = newFile;
    }

    handleRemoveFile(item: FileItem) {
        console.log(item);
        this.files = this.files.filter((c) => c.name != item.file.name);
        this.uploader.queue = this.uploader.queue.filter(
            (c) => c.file.name != item.file.name
        );
    }

    onChangeDividaPagamento(divida){

    }

    check(){
                
        let erros:string[] = [];

        if(!this.valorPagamentoDivida || this.valorPagamentoDivida == 0){
            erros.push("Valor do pagamento deve ser informado!");            
        }

        if (this.files == undefined || this.files.length == 0) {
            erros.push("Coprovante não foi anexado!");
        }

        if (this.files != undefined && this.files.length > 1) {
            erros.push("Só pode ser informado 1 comprovante!");
        }

        if (this.files != undefined && this.files.length == 1 && this.files[0].type != 'application/pdf') {
            erros.push("Comprovante deve está no formato PDF!");
        }        

        if(erros.length > 0){
            let htmlTexto: string = '<ul style="text-align: left">';

            erros.forEach(c=>{
                htmlTexto+=`<li>${c}</li>`;
            })

            htmlTexto += "</ul>";

            this.swalWithBootstrapButtons.fire({
                title: 'Validações',                        
                html: htmlTexto,
                confirmButtonColor: '#FFD950 !important;',
                showCloseButton: true,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Ok',
            });
        }

        return erros.length == 0;
    }
}
