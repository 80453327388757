import { DividaPagamentosService } from './../../../../services/divida-pagamentos.service';
import { TipoAcordo, TipoAcordoLabel } from './../../../../enums/Acordo/tipo-acordo.enum';
import { ModalAprovarAcordoComponent } from './../modal-aprovar-acordo/modal-aprovar-acordo/modal-aprovar-acordo.component';
import { AuthService } from 'src/app/auth/auth.service';
import { AtividadeService } from './../../../../services/atividade.service';
import { Atividade } from './../../../../models/operacionais/atividade/atividade';
import { Guid } from 'guid-typescript';
import { ProcessoDividaResponse } from './../../../../models/operacionais/operacao/processo-divida-response';
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../../../app.service';
import { NgbDateParserFormatter, NgbDropdownConfig, NgbModal, NgbModalRef, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/services/shared/modal-service';
import { NgbDateCustomParserFormatter } from '../../../../utils/dateformat';
import { ProcessoOperacao } from 'src/app/models/operacionais/operacao/processo-operacao';
import { DividaService } from 'src/app/services/divida.service';
import { ProcessoDivida } from 'src/app/models/operacionais/operacao/processo-divida';
import { OcorrenciaService } from 'src/app/services/ocorrencia.service';
import { TagService } from 'src/app/services/tag.service';
import { Ocorrencia } from 'src/app/models/operacionais/ocorrencia/ocorrencia';
import { PessoaService } from 'src/app/services/pessoa.service';
import { TelefoneDevedor } from 'src/app/models/contato-devedor/telefone-devedor';
import { EnderecoDevedor } from 'src/app/models/contato-devedor/endereco-devedor';
import { MidiasSociaisDevedor } from 'src/app/models/contato-devedor/midias-sociais-devedor';
import { OcorrenciaListaResponse } from 'src/app/models/operacionais/ocorrencia/ocorrencia-lista-response';
import { ProcessosService } from 'src/app/services/processos.service';
import { ProcessoArquivoResponse } from 'src/app/models/operacionais/operacao/processo-arquivo-response';
import { ProcessoArquivo } from 'src/app/models/operacionais/operacao/processo-arquivo';
import { UtilsService } from 'src/app/utils/utils.service';
import { Arquivo } from 'src/app/models/shared/arquivo';
import Swal from 'sweetalert2';
import { SelectList } from 'src/app/models/select-list';
import { SelectListService } from 'src/app/services/shared/select-list.service';
import { Acordo } from 'src/app/models/operacionais/acordo/acordo';
import { AcordoService } from 'src/app/services/acordo.service';
import { AcordoResponse } from 'src/app/models/operacionais/acordo/acordo-response';
import { AcordoListaResponse } from 'src/app/models/operacionais/acordo/acordo-lista-response';
import { TipoStatus, TipoStatusLabel } from 'src/app/enums/tipo-status.enum';
import { TipoOcorrencia, TipoOcorrenciaLabel } from 'src/app/enums/tipo-ocorrencia.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResultadoModal } from 'src/app/services/shared/resultado-modal';
import { Subject } from 'rxjs';
import { ModalAprovarDividaPagamentoComponent } from '../modal-aprovar-divida-pagamento/modal-aprovar-divida-pagamento.component';
import { map } from 'rxjs/operators';
import { Modulo } from 'src/app/enums/modulo.enum';
import { ModalAprovarDividaDescontoComponent } from '../modal-aprovar-divida-desconto/modal-aprovar-divida-desconto.component';
import { Parametro } from 'src/app/models/shared/parametro';
import { ParametroService } from 'src/app/services/parametro.service';
import { ParametroChaveType } from 'src/app/enums/parametro/parametro-chave-type.enum';
import { ProcessoOriginalProcessoAcordo } from 'src/app/models/operacionais/operacao/processo-original-processo-acordo';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
import { Console } from 'console';


@Component({
    selector: 'app-modal-contato-processo-cobrador',
    templateUrl: './modal-contato-processo-cobrador.component.html',
    styleUrls: [
        '../../../../../vendor/styles/pages/gestao-processos.scss',
        './modal-contato-processo-cobrador.component.css'
    ],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
        NgbDropdownConfig
    ]

})

export class ModalContatoProcessoCobradorComponent implements OnInit, AfterContentChecked {

    public loadingAcordos:Boolean = true;

    public loadingProcesso: Boolean = false;
    public loadingProcessoFirst: Boolean = true;

    modalRef: NgbModalRef;
    resultConfirmDialog$ = new Subject<ResultadoModal>();
    isIE10 = false;
    abaAtiva: string = '';
    totalDividaQuitacao: number = 0;
    valorComprovanteDivida: number = 0;

    public despesasAdministrativas = '';

    justificativa: string;

    processoArquivos: ProcessoArquivo[];

    dividaL: any[] = [];

    dividas: any[] = [];

    dividasExtrato: any =[];

    acordosL: Acordo[] = [];

    processoOriginalProcessoAcordoL: ProcessoOriginalProcessoAcordo[] = [];
    idExternoProcessoOriginal = null;
    idExternoProcessoAcordo = null;

    contatosDevedorEnderecos: EnderecoDevedor[] = [];
    contatosDevedorTelefones: TelefoneDevedor[] = [];
    contatosDevedorEmails: MidiasSociaisDevedor[] = [];

    @ViewChild('productTabs') productTabs;
    @ViewChild('productTabs2') productTabs2;

    public processo: ProcessoOperacao;
    public ocorrencias: Ocorrencia[] = [];

    valorPagamentoDivida: number = 0;
    valorRetido: boolean = false;
    valorDescontoDivida: number = 0;
    valorTaxaAdministrativaDivida: number = 0;

    pagamentoIsento = false;

    acordoModel: Acordo;
    ListTipoAcordo: SelectList[] = [];
    public options: { readonly?: Boolean, previousTab?: String };
    parcelas = [];
    public currentDateObj: any;
    camposSimulacao = {
        percentualJuros: 0,
        percentualTaxaAdministrativa: 0,
        percentualAtualizacaoCusta: 0,
        jurosProtesto: 0,
        percentualMulta: 0,
        simples: true,
        composto: false,
        dataInicialParcelas: new Date(),
        dataCalculos: new Date(),
        numParcelas: 1,
        intervaloDias: 1,
        diaFixoOption: "Não",
        diaFixoValor: 1,
        acrescimoOuDesconto: "Desconto",
        tipo: TipoAcordo.Duplicata,
        porcentagemOuValorOption: "%",
        porcentagemOuValor: 0,
    }

    simulacaoTotais = {
        valorOriginal: 0,
        juros: 0,
        multa: 0,
        desconto: 0,
        totalEmAberto: 0,
        protesto: 0,
        taxaAdministrativa: 0,
        valorTotalAPagar: 0,
    }
    acordo: any = {};
    calculo: any = {};

    credor: any;
    devedor: any;

    isMaster: Boolean;
    isCredor: Boolean;

    sumarioTaxaAdministrativa: Number;
    sumarioDesconto: Number;
    sumarioJuros: Number;
    sumarioCustas: Number;
    sumarioAtualizacaoCustas: Number;
    sumarioMulta: Number;

    parametroL: Parametro[];

    qtdDividasOriginais = 0;
    pt_calendar: any = {};

    selecteAllDividasPagamento = false;

    tabDividasSelected = 0;

    isMovido: Boolean;

    constructor(
        private appService: AppService,
        public toastrService: ToastrService,
        public modalService: ModalService,
        public dividaService: DividaService,
        private ngbModal: NgbModal,
        public tagService: TagService,
        public ocorrenciaService: OcorrenciaService,
        public pessoaService: PessoaService,
        public processoService: ProcessosService,
        public utilsService: UtilsService,
        public atividadeService: AtividadeService,
        config: NgbDropdownConfig,
        private selectListService: SelectListService,
        public acordoService: AcordoService,
        private spinner: NgxSpinnerService,
        private changeDetector: ChangeDetectorRef,
        private authService: AuthService,
        private parametroService: ParametroService,
        private primeNgConfig: PrimeNGConfig,
        private translateService: TranslateService,
        private dividaPagamentoService: DividaPagamentosService
    ) {
        this.isIE10 = this.appService.isIE10;
        config.placement = 'left-center';
        this.isMaster = authService.isMaster();

    }

    ngAfterContentChecked(): void {
        this.changeDetector.detectChanges();
    }

    valorTotalDividaCorrigido: number = 0;
    valorSaldoDivida: number = 0;

    getAllData() {


        this.loadingProcesso = this.loadingProcessoFirst ? true : false;        
        if (this.loadingProcessoFirst) {
            this.loadingProcessoFirst = false;
        }

        this.ListTipoAcordo = this.selectListService.getTipoAcordo();

        this.processo = this.modalRef.componentInstance.processo;                

        this.processoService.getProcessoOriginaisProcessoAcordos(this.processo.id).subscribe(res => {
            this.processoOriginalProcessoAcordoL = res.data;
            this.getIdsExternosProcessoOriginalProcessoAcordo();
        }, error => {

        });

        this.processoService.getById(this.processo.id).subscribe(res => {
            this.processo = res.data;
            
            this.isMovido = this.processo.situacaoProcesso != undefined && this.processo.situacaoProcesso.situacaoPai != undefined && this.processo.situacaoProcesso.situacaoPai.idExterno == 120;
            console.log(this.processo);  

            this.camposSimulacao.percentualJuros = this.processo.percentualJuros;
            this.camposSimulacao.percentualMulta = this.processo.percentualMulta;
            this.camposSimulacao.percentualTaxaAdministrativa = this.processo.percentualTaxaAdministrativa;
            this.camposSimulacao.percentualAtualizacaoCusta = this.processo.percentualAtualizacaoCusta;

            this.pessoaService.getById(this.processo.devedorId).subscribe((res) => {
                this.devedor = res.data;
                this.getContatosDevedor(this.processo.devedorId);
            });

            //comentado, pois agora o processo pode ser modificado mesmo após ser finalizado
            // if (this.processo.isFinalizado) {
            //     this.options.readonly = true;
            // }
            this.loadingProcesso = false;

            if (this.modalRef.componentInstance && this.modalRef.componentInstance.options && this.modalRef.componentInstance.options.previousTab) {
                setTimeout(() => {
                    this.productTabs.select(this.modalRef.componentInstance.options.previousTab);
                    this.modalRef.componentInstance.options.previousTab = "";
                }, 500);
            }
        });

        this.getDividasEAcordos();

        this.ocorrenciaService.getAllByProcesso(this.processo.id).subscribe((res: OcorrenciaListaResponse) => {
            this.ocorrencias = res.data;
        });

        this.processoService.getAllProcessoArquivo(this.processo.id).subscribe((res: ProcessoArquivoResponse) => {
            this.processoArquivos = res.data;
        });


    }


    getDividasEAcordos() {
        this.dividaService.getAllByProcesso(this.processo.id).subscribe(async (res: ProcessoDividaResponse) => {
            this.dividaL = res.data;
            console.log(res.data);
            this.qtdDividasOriginais = this.getQtdDividasOriginais(this.dividaL);

            this.sumarioTaxaAdministrativa = this.getTaxaAdministrativa(this.dividaL);

            this.valorTotalDividaCorrigido = 0;
            this.valorSaldoDivida = 0;

            this.dividaL.forEach(divida => {
                divida.valorTotal = parseFloat((divida.valorAberto + divida.juros + divida.multa + divida.valorCustasProtesto + divida.valorAtualizacaoCustas).toFixed(2));
            });

            var taxasAdministrativas = this.filterDivida(3);
            var descontos = this.filterDivida(2);

            if (taxasAdministrativas.length > 0) {
                //this.sumarioTaxaAdministrativa = this.getTaxaAdministrativa(this.dividaL);
                //this.sumarioTaxaAdministrativa = this.filterDivida(3).map(a => a.valorTotal).reduce(function(a, b) { return a + b; });
            }

            if (descontos.length > 0) {
                this.sumarioDesconto = this.filterDivida(2).map(a => a.valorDesconto).reduce(function (a, b) { return a + b; });
            }

            if (this.dividaL.length > 0) {
                this.sumarioJuros = this.dividaL.map(a => a.juros).reduce(function (a, b) { return a + b; });
                this.sumarioCustas = this.dividaL.map(a => a.valorCustasProtesto).reduce(function (a, b) { return a + b; });
                this.sumarioAtualizacaoCustas = this.dividaL.map(a => a.valorAtualizacaoCustas).reduce(function (a, b) { return a + b; });

                this.sumarioMulta = this.dividaL.map(a => a.multa).reduce(function (a, b) { return a + b; });
            }
            //em teste
            this.calculo = await this.calculos();
            this.acordo = await this.calculos(true);

            this.acordoService.getAllByProcesso(this.processo.id).subscribe((res: AcordoListaResponse) => {
                this.loadingAcordos = false;
                this.acordosL = res.data;

                //acordos aprovados ficam em primeiro
                this.acordosL.forEach((element, index) => {
                    if (element.ocorrencia.situacaoId == Guid.parse(this.parametroL.filter(x => x.chave.toString() == ParametroChaveType[ParametroChaveType.SITUACAO_ACORDO_FILHO_APROVADO].toString())[0].valor)) {
                        this.moveElementArray(this.acordosL, index, 0);
                    }
                });
                //this.checkarStatusDivida();
            });

            this.selecionarTipoDividas(this.tabDividasSelected);

        }, erro => {
            var teste = "";
        });
    }

    corSituacoTaxaAdministrativa(divida: any) {

        return 'Green';
    }

    corSituacaoExtrato(divida:any){
        var cor = '';

        switch(divida.statusCobrancaId){
            //Quitado Integral
            case 1:
                cor = 'Green';
                break;
            //Baixa Parcial
            case 2:
                cor = 'Yellow'
                break;
            //Em Aberto
            case 3:
                cor = 'Red'
                break;
            //Baixado Pelo Credor
            case 4:
                cor = 'Green'
                break;
            //Declarado Inexigível Judicial
            case 5:
                cor = 'Red'
                break;
        }

        return cor;
    }

    corSituacoPagamento(divida: any) {

        var cor = '';

        if (divida.situacao == 'Situação Pendente Aprovação') {
            cor = 'Red';
        }
        else if (divida.valorSaldoPendente == 0) {
            cor = 'Green';
        }
        else if (divida.valorSaldoPendente > 0) {
            cor = 'Red';
        }

        return cor;


        if (this.modalRef.componentInstance.options && this.modalRef.componentInstance.options.previousTab) {
            setTimeout(() => {
                this.productTabs.select(this.modalRef.componentInstance.options.previousTab);
            }, 500);
        }
    }

    ngOnInit(): void {
        this.translateService.setDefaultLang('pt-br');

        this.primeNgConfig.setTranslation({
            dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            today: 'Hoje',
            clear: 'Limpar'
        });

        this.isCredor = this.authService.isPermissionAvailable(Modulo.OPERACIONAIS_PROCESSOS_CREDOR);

        this.parametroService.getAll().subscribe(res => {
            this.parametroL = res.data;
            
            this.getAllData();
        });

        var teste = this.ngbModal.hasOpenModals()
        /*
        this.modalService.resultConfirmDialog$.asObservable().subscribe(res => {
            if (res != undefined) {
                if (res.result === true && res.page === 'emailCredor' && this.acordoModel) {
                    this.acordoModel.emails = res.lista;
                    this.acordoModel.descricao = res.description
                    this.salvarAcordoCore();
                }
            }
        });
        */


        this.pt_calendar = {
            firstDayOfWeek: 0,
            dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            today: 'Hoje',
            clear: 'Limpar'
        };
    }

    informarValorComprovante() {
        this.valorComprovanteDivida = this.valorPagamentoDivida;
        this.totalDividaQuitacao = this.valorPagamentoDivida;
    }


    informarValorDesconto() {
        this.valorDescontoDivida = this.valorDescontoDivida;
    }

    informarValorTaxaAdministrativa() {
        this.valorTaxaAdministrativaDivida = this.valorTaxaAdministrativaDivida;
    }

    possuiDividas(tipoDivida: number) {

        var result = false;

        switch (tipoDivida) {
            case 1: //Pagamento
                result = this.dividas.filter(x => (x.situacao.nome == 'Novo' || x.situacao.nome == 'Desconto Aprovado' || x.situacao.nome == 'Pagamento Revisado')).length > 0;
                break;
            case 3: //Taxa administrativa
                result = this.dividas.length > 0;
                break;

        }
        return result;

    }

    calcularValorTotalDividas(tipo: string, tipoDivida: number) {

        var valorTotal = 0;

        switch (tipo) {
            case "valorOriginalAberto":
                valorTotal = this.dividas.reduce((accumulator, current) => accumulator + current.valorOriginalAberto, 0);
                break;
            case "valorAberto":
                valorTotal = this.dividas.filter(x => !x.dividaPaiId).reduce((accumulator, current) => accumulator + current.valorAberto, 0);
                break;
            case "juros":
                valorTotal = this.dividas.reduce((accumulator, current) => accumulator + current.juros, 0);
                break;
            case "multa":
                valorTotal = this.dividas.reduce((accumulator, current) => accumulator + current.multa, 0);
                break;
            case "valorCustasProtesto":
                valorTotal = this.dividas.reduce((accumulator, current) => accumulator + current.valorCustasProtesto, 0);
                break;
            case "valorTotal":
                valorTotal = this.dividas.reduce((accumulator, current) => accumulator + current.valorTotal, 0);
                break;
            case "valorPago":
                valorTotal = this.dividas.reduce((accumulator, current) => accumulator + current.valorPago, 0);
                break;
            case "valorPendente":
                valorTotal = this.dividas.reduce((accumulator, current) => accumulator + current.valorSaldoPendente, 0);
                break;
        }

        return valorTotal;
    }

    dividaPago(divida: any) {
        var valorTotal = divida.valorAberto + divida.multa + divida.juros + divida.valorCustasProtesto;

        var valorPago = 0;

        divida.pagamentos.forEach(pagamento => {
            valorPago += pagamento.valorPagamento;
        });

        if (valorPago >= valorTotal) {
            return true;
        }
        else {
            return false;
        }
    }



    public selecionarAba(name) {
        this.abaAtiva = name;

        if (name == "divida" || name == "ocorrencia") {
            this.dividas = null;
            this.getAllData();
            this.selecionarTipoDividas(0);
        }
    }


    public abrirModalAdicionarOcorrencia(processo: ProcessoOperacao) {
        this.modalRef.close();
        this.modalService.abrirModalAdicionarOcorrencia(processo, null, this.options);
    }

    public abrirModalAdicionarAtividade(processo: ProcessoOperacao) {
        this.modalRef.close();
        this.modalService.abrirModalAdicionarAtividade(processo, null, this.options);
    }

    public abrirModalAdicionarDocumento(processo: ProcessoOperacao) {
        this.modalRef.close();
        this.modalService.abrirModalProcessoAdicionarDocumento(processo);
    }

    abrirModalAdicionarDividaTaxaAdministrativa(processo: ProcessoOperacao) {
        if (this.abaAtiva != 'divida') {
            this.toastrService.error("Por favor, acesse a aba de dívidas!", 'Atenção', { timeOut: 10000 });
        }
        else {
            if (this.valorTaxaAdministrativaDivida == 0) {
                this.toastrService.error("Por favor, informe o valor da taxa administrativa", 'Atenção', { timeOut: 10000 });
            }
            else {
                var valorTaxaAdministrativaDivida = this.valorTaxaAdministrativaDivida;

                this.modalRef.close();
                this.modalService.abrirModalAdicionarDividaTaxaAdministrativa(processo, valorTaxaAdministrativaDivida);
            }
        }
    }

    public abrirModalAdicionarDivida(processo: ProcessoOperacao) {
        if (this.abaAtiva != 'divida') {
            this.toastrService.error("Por favor, acesse a aba de dívidas!", 'Atenção', { timeOut: 10000 });
        }
        else {
            if (this.valorPagamentoDivida == 0) {
                this.toastrService.error("Por favor, informe o valor do comprovante de pagamento!", 'Atenção', { timeOut: 10000 });
            }
            else {
                var dividas = this.dividas.filter(o => o.selecionado === true);
                var dividasPendentes = this.dividas.filter(o => o.valorSaldoPendente > 0);
                var valorComprovanteDivida = this.valorPagamentoDivida;
                var valorRetido = this.valorRetido;
                var pagamentoIsento = this.pagamentoIsento;

                if (dividasPendentes.length == 0) {
                    this.toastrService.error(`Por favor, selecione ao menos 1 divida para continuar`, 'Atenção', { timeOut: 10000, enableHtml: true });
                }
                else {
                    if (dividas.length == 0) {

                        if (pagamentoIsento) {
                            this.toastrService.error("Pagamento isento necessita de 1 divida selecionada", 'Atenção', { timeOut: 10000, enableHtml: true });
                            return;
                        }

                        var valorTotalDividas = 0;
                        this.dividas.forEach(divida => {

                            divida.retido = valorRetido;
                            divida.isento = pagamentoIsento;

                            if (divida.retido) {
                                divida.valorAtualizacaoCustas2 = divida.valorAtualizacaoCustas;
                                divida.valorAtualizacaoCustas = 0;
                                divida.valorTotal -= divida.valorAtualizacaoCustas2;

                                divida.juros2 = divida.juros;
                                divida.juros = 0;

                                divida.valorTotal -= divida.juros2;
                            }

                            if (divida.isento) {
                                var encargos = (divida.juros + divida.multa + divida.valorCustasProtesto + divida.valorAtualizacaoCustas);
                                divida.valorTotal -= encargos;
                                divida.juros = 0;
                                divida.multa = 0;
                                divida.valorCustasProtesto = 0;
                                divida.valorAtualizacaoCustas = 0;
                            }

                            if (divida.situacao.nome == 'Novo') {
                                if (valorTotalDividas < valorComprovanteDivida) {
                                    var valorParcela = 0;

                                    if (divida.valorTotal <= valorComprovanteDivida) {
                                        valorParcela = divida.valorTotal;
                                    } else {
                                        valorParcela = valorComprovanteDivida;
                                    }
                                    divida.selecionado = true;
                                    //
                                    divida.valorPagamento = valorParcela;
                                    //divida.valorPago = valorParcela;
                                    valorTotalDividas += divida.valorTotal;

                                    valorComprovanteDivida -= divida.valorTotal;
                                }
                                else {
                                    if (valorComprovanteDivida > 0) {

                                        var valorParcela = 0;

                                        if (divida.valorTotal <= valorComprovanteDivida) {
                                            valorParcela = divida.valorTotal;
                                        } else {
                                            valorParcela = valorComprovanteDivida;
                                        }
                                        divida.selecionado = true;
                                        //
                                        divida.valorPagamento = valorParcela;
                                        //divida.valorPago = valorParcela;
                                        valorTotalDividas += divida.valorTotal;
                                        valorComprovanteDivida -= divida.valorTotal;
                                    }
                                }
                            }

                            divida.valorPendente = divida.valorTotal - divida.valorPagamento;

                        });

                        
                        dividas = this.dividas.filter(o => o.selecionado === true);

                        this.modalRef.close();
                        this.modalService.abrirModalAdicionarDividaPagamento(processo, this.valorPagamentoDivida, dividas);

                        /*
                        if((Math.floor(valorComprovanteDivida * 100) / 100) > 0)
                        {
                            this.toastrService.error(`O comprovante de pagamento não pode sobrar valor residual.<br>Valor residual de R$ ${valorComprovanteDivida.toFixed(2)}`, 'Atenção', { timeOut: 10000,  enableHtml: true });
                        }
                        else{
                            dividas = this.dividaL.filter(o => o.selecionado === true);
                            this.modalRef.close();
                            this.modalService.abrirModalAdicionarDividaPagamento(processo, this.valorPagamentoDivida ,dividas);
                        }
                        */
                    }
                    else {

                        var dividas = this.dividas.filter(o => o.selecionado === true);

                        if (dividas.length == 0) {
                            this.toastrService.error(`Por favor, selecione ao menos 1 divida para continuar`, 'Atenção', { timeOut: 10000, enableHtml: true });
                        }
                        else {

                            if (pagamentoIsento && dividas.length > 1) {
                                this.toastrService.error("Pagamento isento pode ser feito com apenas 1 divida selecionada", 'Atenção', { timeOut: 10000, enableHtml: true });
                                return;
                            }

                            var totalParcela: number = 0;
                            totalParcela = this.dividas.filter(item => item.selecionado == true).reduce((accumulator, current) => accumulator + current.valorTotal, 0);

                            if (valorRetido) {
                                totalParcela = this.dividas.filter(item => item.selecionado == true).reduce((accumulator, current) => accumulator + current.valorTotal - current.juros, 0);
                            }
                            if (pagamentoIsento) {
                                totalParcela = this.dividas.filter(item => item.selecionado == true).reduce((accumulator, current) => accumulator + current.valorTotal - current.juros - current.multa - current.valorCustasProtesto - current.valorAtualizacaoCustas, 0);
                            }


                            var formatoValor = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' };

                            var valorResidual =  parseFloat((this.valorPagamentoDivida - totalParcela).toFixed(2));
                            if (valorResidual > 0 && !pagamentoIsento) {
                                this.toastrService.error(`O comprovante de pagamento não pode sobrar valor residual.<br>Valor residual de ${valorResidual.toLocaleString('pt-BR', formatoValor)}`, 'Atenção', { timeOut: 10000, enableHtml: true });
                                return;
                            }

                            // if (totalParcela < this.valorPagamentoDivida && !pagamentoIsento) {
                            //     var valorResidual = this.valorPagamentoDivida - totalParcela;
                            //     this.toastrService.error(`O comprovante de pagamento não pode sobrar valor residual.<br>Valor residual de ${valorResidual.toLocaleString('pt-BR', formatoValor)}`, 'Atenção', { timeOut: 10000, enableHtml: true });
                            //     return;
                            // }

                            var valorComprovante = this.valorPagamentoDivida;
                            dividas.forEach(divida => {

                                divida.retido = valorRetido;
                                divida.isento = pagamentoIsento;

                                if (divida.retido) {
                                    divida.valorAtualizacaoCustas2 = divida.valorAtualizacaoCustas;
                                    divida.valorAtualizacaoCustas = 0;
                                    divida.valorTotal -= divida.valorAtualizacaoCustas2;

                                    divida.juros2 = divida.juros;
                                    divida.juros = 0;

                                    divida.valorTotal -= divida.juros2;
                                    //divida.valorTotal -= divida.valorAtualizacaoCustas;

                                    divida.valorPendente -= divida.juros2;
                                }


                                if (divida.isento) {

                                    var encargos = (divida.juros + divida.multa + divida.valorCustasProtesto + divida.valorAtualizacaoCustas);
                                    divida.valorTotal -= encargos;

                                    divida.juros = 0;
                                    divida.multa = 0;
                                    divida.valorCustasProtesto = 0;
                                    divida.valorAtualizacaoCustas = 0;
                                    divida.juros += valorResidual;
                                    divida.valorTotal += divida.juros;

                                }

                                if (valorComprovante >= divida.valorTotal) {
                                    divida.valorPagamento = divida.valorTotal;
                                    valorComprovante -= divida.valorTotal;
                                }
                                else {
                                    divida.valorPagamento = valorComprovante;
                                    valorComprovante = 0;
                                }

                                divida.valorPendente = divida.valorTotal - divida.valorPagamento;

                            });

                            this.modalRef.close();
                            this.modalService.abrirModalAdicionarDividaPagamento(processo, this.valorPagamentoDivida, dividas);
                        }
                    }
                }
            }
        }
    }


    public abrirModalAdicionarDividaDesconto(processo: ProcessoOperacao) {
        if (this.abaAtiva != 'divida') {
            this.toastrService.error("Por favor, acesse a aba de dívidas / descontos!", 'Atenção', { timeOut: 10000 });
        }
        else {
            if (this.valorDescontoDivida == 0) {
                this.toastrService.error("Por favor, informe o valor do desconto!", 'Atenção', { timeOut: 10000 });
            }
            else {
                var dividasPendentes = this.dividas.filter(o => o.valorSaldoPendente > 0 && o.tipoParcela != 'desconto');
                var valorComprovanteDesconto = this.valorDescontoDivida;

                if (dividasPendentes.length == 0) {
                    this.toastrService.error(`Não existe nenhuma divida para aplicar o desconto.`, 'Atenção', { timeOut: 10000, enableHtml: true });
                }
                else {
                    if (this.dividas.filter(o => o.selecionado === true).length == 0) {
                        var valorTotalDividas = 0;
                        this.dividas.forEach(divida => {

                            if (divida.situacao.descricao == 'Novo' || divida.situacao.descricao == 'Desconto Aprovado') {
                                if (valorTotalDividas < valorComprovanteDesconto) {
                                    var valorParcela = 0;

                                    if (divida.valorTotal <= valorComprovanteDesconto) {
                                        valorParcela = divida.valorTotal;
                                    } else {
                                        valorParcela = valorComprovanteDesconto;
                                    }

                                    divida.selecionado = true;
                                    divida.valorPagamento = valorParcela;
                                    divida.valorDesconto = valorParcela;
                                    divida.valorPago = valorParcela;
                                    valorTotalDividas += divida.valorTotal;
                                    valorComprovanteDesconto -= divida.valorTotal;
                                }
                                else {
                                    if (valorComprovanteDesconto > 0) {
                                        var valorParcela = 0;

                                        if (divida.valorTotal <= valorComprovanteDesconto) {
                                            valorParcela = divida.valorTotal;
                                        } else {
                                            valorParcela = valorComprovanteDesconto;
                                        }

                                        divida.selecionado = true;
                                        //
                                        divida.valorPagamento = valorParcela;
                                        //divida.valorPago = valorParcela;
                                        valorTotalDividas += divida.valorTotal;
                                        valorComprovanteDesconto -= divida.valorTotal;
                                    }
                                }
                            }

                        });

                        this.calcularTotalDivida();

                        var dividas = this.dividas.filter(o => o.selecionado === true);
                        this.modalRef.close();
                        this.modalService.abrirModalAdicionarDividaDesconto(processo, this.valorDescontoDivida, dividas);
                    }
                    else {

                        var dividasSelecionadas = this.dividas.filter(o => o.selecionado === true);

                        if (dividasSelecionadas.length == 0) {
                            this.toastrService.error(`Por favor, selecione ao menos 1 divida para continuar`, 'Atenção', { timeOut: 10000, enableHtml: true });
                        }
                        else {
                            var totalParcela = dividasSelecionadas.reduce((accumulator, current) => accumulator + current.valorTotal, 0);
                            var formatoValor = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' };

                            if (totalParcela < this.valorDescontoDivida) {
                                var valorResidual = this.valorDescontoDivida - totalParcela;
                                this.toastrService.error(`O desconto não pode sobrar valor residual.<br>Valor residual de R$ ${valorResidual.toLocaleString('pt-BR', formatoValor)}`, 'Atenção', { timeOut: 10000, enableHtml: true });
                            }
                            else {

                                var valorComprovante = this.valorDescontoDivida;
                                dividasSelecionadas.forEach(divida => {

                                    if (valorComprovante >= divida.valorTotal) {
                                        divida.valorDesconto = divida.valorTotal;
                                        divida.valorPagamento = divida.valorTotal;
                                        valorComprovante -= divida.valorTotal;
                                    }
                                    else {
                                        divida.valorDesconto = valorComprovante;
                                        divida.valorPagamento = valorComprovante;
                                        valorComprovante = 0;
                                    }

                                });

                                // var dividas = this.dividas.filter(o => o.selecionado === true);

                                this.modalRef.close();
                                this.modalService.abrirModalAdicionarDividaDesconto(processo, this.valorDescontoDivida, dividasSelecionadas);
                            }
                        }
                    }
                }
            }
        }
    }

    tipoPagamento: number = 0;
    mensagemTipoPagamento: string = "";
    changeTipoPagamento() {
        if (this.tipoPagamento == 3) {
            this.valorRetido = true;
            this.pagamentoIsento = false;
            this.mensagemTipoPagamento = "Calculado: SIC<br>Repasse: Retém encargos";
        }
        else if (this.tipoPagamento == 2) {
            this.valorRetido = false;
            this.pagamentoIsento = true;
            this.mensagemTipoPagamento = "Calculado: Cliente<br>Repasse: Integral";
        }
        else if (this.tipoPagamento == 1) {
            this.valorRetido = false;
            this.pagamentoIsento = false;
            this.mensagemTipoPagamento = "Calculado: SIC<br>Repasse: Integral";
        }

        this.recalculoDividasSwitch(this.dividas);
    }


    switchPagamentoIsento(event) {

        if (this.pagamentoIsento) {
            this.pagamentoIsento = false;
            //this.valorRetido = true;
        }
        else {
            this.pagamentoIsento = true;
            this.valorRetido = false;
        }

        this.recalculoDividasSwitch(this.dividas);
    }


    switchPagamentoRetido(event) {
        if (this.valorRetido) {
            this.valorRetido = false;
            //this.pagamentoIsento = true;
        }
        else {
            this.valorRetido = true;
            this.pagamentoIsento = false;
        }

        this.recalculoDividasSwitch(this.dividas);
    }

    valorTotalPagamentoDivida = 0;

    public calcularValorPagamento(e: any, divida: any) {
        
        if (e.target.checked) {

            if (this.pagamentoIsento) {
                this.valorTotalPagamentoDivida += divida.valorTotal;
            }

            if (this.valorRetido) {
                this.valorTotalPagamentoDivida += divida.valorTotal;
            }
            if (this.valorComprovanteDivida >= divida.valorTotal) {
                divida.valorQuitacao = divida.valorTotal;
                this.valorComprovanteDivida -= divida.valorQuitacao;
            }
            else {
                divida.valorQuitacao = this.valorComprovanteDivida
                this.valorComprovanteDivida = 0;
            }
            divida.SelecaoManual = true;
        }
        else {
            this.valorTotalPagamentoDivida -= divida.valorTotal;

            this.valorComprovanteDivida += divida.valorQuitacao;
            divida.valorQuitacao = 0;
            divida.SelecaoManual = false;
        }
        this.calcularValorPagamentoDivida();
    }

    public calcularValorPagamentoDivida() {
        var totalDividaPagamento = 0;

         totalDividaPagamento = this.dividas.filter(item => item.selecionado == true).reduce((accumulator, current) => accumulator + (current.valorTotal), 0);

        if (this.valorRetido) {
            totalDividaPagamento = this.dividas.filter(item => item.selecionado == true).reduce((accumulator, current) => accumulator + (current.valorTotal - current.juros - current.valorAtualizacaoCustas), 0);
        }

        if (this.pagamentoIsento) {
            totalDividaPagamento = this.dividas.filter(item => item.selecionado == true).reduce((accumulator, current) => accumulator + (current.valorTotal - current.juros - current.multa - current.valorCustasProtesto - current.valorAtualizacaoCustas), 0);
        }

        this.valorPagamentoDivida = totalDividaPagamento;
        this.totalDividaQuitacao = totalDividaPagamento;
    }

    public calcularTotalDivida() {
        var totalParcela = this.dividas.filter(item => item.selecionado == true).reduce((accumulator, current) => accumulator + current.valorTotal, 0);

        this.totalDividaQuitacao = totalParcela;
    }

    close() {
        this.modalRef.close(
          {
            result: true,
            page: this.modalRef.componentInstance.page,
            acao: 'update processo',
            data:this.processo.id
          });
    }

    dismiss() {
        this.modalRef.dismiss();
    }

    async calculos(calcularDesconto?) {


        var dataReferencia = new Date(this.camposSimulacao.dataCalculos.getFullYear(),
            this.camposSimulacao.dataCalculos.getMonth(),
            this.camposSimulacao.dataCalculos.getDate());


        var jurosTotal = 0;
        var multaTotal = 0;
        var custasTotal = 0;
        var valorOriginal = 0;
        var totalEmAberto = 0;
        var taxaAdministrativa = 0;
        var atualizacaoCusta = 0;
        var resp;
        var calculojuros = 1;

        if (this.camposSimulacao.composto) {
          calculojuros = 2;
        }

        await this.processoService.getCalculosJurosEMulta({
            processoId: this.processo.id,
            dataReferencia: dataReferencia,
            percentualMulta: this.camposSimulacao.percentualMulta,
            percentualJuros: this.camposSimulacao.percentualJuros,
            SimplesOuComposto: calculojuros
        }).then(x => {

            var resp = x["data"];
            var hasAcordo = this.dividaL.filter(x => x.acordoId != null).length > 0;

           resp.dividas.forEach(divida => {

         if (hasAcordo) {
            if (divida.acordoId != null) {
              jurosTotal += divida.juros;
              multaTotal += divida.multa;
              custasTotal += divida.valorCustasProtesto;
              atualizacaoCusta += divida.valorAtualizacaoCustas;
              totalEmAberto += divida.valorAberto;
              taxaAdministrativa += (this.getTotalValorAbertoDividasVencidas(divida) + divida.multa + divida.juros + divida.valorCustasProtesto + (divida.valorAtualizacaoCustas != null
                ? divida.valorAtualizacaoCustas
                : 0)) * (this.camposSimulacao.percentualTaxaAdministrativa / 100);
            }
            else {
              valorOriginal += divida.valorOriginal;
            }
          } else {
            if (divida.acordoId == null && divida.valorSaldoPendente > 0) {
              jurosTotal += divida.juros;
              multaTotal += divida.multa;
              custasTotal += divida.valorCustasProtesto;
              atualizacaoCusta += divida.valorAtualizacaoCustas;
              valorOriginal += divida.valorOriginal;
              totalEmAberto += divida.valorAberto;
              taxaAdministrativa += (this.getTotalValorAbertoDividasVencidas(divida) + divida.multa + divida.juros + divida.valorCustasProtesto + (divida.valorAtualizacaoCustas != null
                    ? divida.valorAtualizacaoCustas
                    : 0)) * (this.camposSimulacao.percentualTaxaAdministrativa / 100);
            }
          }
        });
      });

        return {
            desconto: 0,
            valorOriginal: valorOriginal,
            juros: jurosTotal,
            multa: multaTotal,
            custas: custasTotal,
            totalEmAberto: totalEmAberto,
            taxaAdministrativa: taxaAdministrativa,
            atualizacaoCusta: atualizacaoCusta
        }

    }

    editar(ocorrencia) {

    }

    getContatosDevedor(id) {
        this.contatosDevedorEnderecos = [];
        this.contatosDevedorTelefones = [];
        this.contatosDevedorEmails = [];

        this.pessoaService.getContatosPessoa(id)
            .subscribe(res => {
                res.data.enderecos.forEach(element => {
                    let endereco = new EnderecoDevedor();
                    endereco.cep = this.checkChaves(element.find(x => x.chave == "endereco-cep"));
                    endereco.cidade = this.checkChaves(element.find(x => x.chave == "endereco-cidade"));
                    endereco.logradouro = this.checkChaves(element.find(x => x.chave == "endereco-logradouro"));
                    endereco.uf = this.checkChaves(element.find(x => x.chave == "endereco-uf"));
                    this.contatosDevedorEnderecos.push(endereco);
                });
                res.data.telefones.forEach(element => {
                    let telefone = new TelefoneDevedor();
                    telefone.contato = this.checkChaves(element.find(x => x.chave == "telefoneDevedor-contato"));
                    telefone.numero = this.checkChaves(element.find(x => x.chave == "telefoneDevedor-numero"));
                    telefone.ramal = this.checkChaves(element.find(x => x.chave == "telefoneDevedor-ramal"));
                    telefone.tipo = this.checkChaves(element.find(x => x.chave == "telefoneDevedor-tipo"));
                    this.contatosDevedorTelefones.push(telefone);
                });
                res.data.emails.forEach(element => {
                    let midia = new MidiasSociaisDevedor();
                    midia.email = this.checkChaves(element.find(x => x.chave == "enderecoEletronico-email"));
                    midia.facebook = this.checkChaves(element.find(x => x.chave == "enderecoEletronico-facebook"));
                    midia.instagram = this.checkChaves(element.find(x => x.chave == "enderecoEletronico-instagram"));
                    midia.linkedin = this.checkChaves(element.find(x => x.chave == "enderecoEletronico-linkedin"));
                    midia.telegram = this.checkChaves(element.find(x => x.chave == "enderecoEletronico-telegram"));
                    midia.twitter = this.checkChaves(element.find(x => x.chave == "enderecoEletronico-twitter"));
                    this.contatosDevedorEmails.push(midia);
                });

                let emailCadastro = new MidiasSociaisDevedor();
                if (this.devedor && this.devedor.email) {
                    emailCadastro.email = this.devedor.email;

                    this.contatosDevedorEmails.push(emailCadastro);
                }
            }, error => {
                //this.spinner.hide();
            });
    }

    checkChaves(expression) {
        if (expression != null) {
            return expression.valor;
        } else {
            return "";
        }
    }

    downloadOcorrenciaArquivo(ocorrenciaArquivo: Arquivo) {
        this.ocorrenciaService.downloadArquivo(ocorrenciaArquivo).subscribe(
            data => {
                this.utilsService.renderDownload(data, ocorrenciaArquivo.nome as string);
            }
        );
    }

    downloadProcessoArquivo(processoArquivo: ProcessoArquivo) {
        if (!this.isArquivoOcorrencia(processoArquivo)) {
            this.processoService.downloadArquivo(processoArquivo).subscribe(
                data => {
                    this.utilsService.renderDownload(data, processoArquivo.nome as string);
                }
            );
        } else {
            this.downloadOcorrenciaArquivo(processoArquivo);
        }
    }

    isArquivoOcorrencia(processoArquivo: ProcessoArquivo) {
        return Guid.EMPTY != processoArquivo.ocorrenciaId.toString();
    }

    getTooltipProcessoArquivo(processoArquivo: ProcessoArquivo) {
        return this.isArquivoOcorrencia(processoArquivo) ? "Documento adicionado via ocorrência" : "";
    }

    editarOcorrencia(ocorrencia: Ocorrencia) {
        this.modalRef.close();
        this.modalService.abrirModalAdicionarOcorrencia(this.processo, ocorrencia, this.options);
    }

    editarAtividade(atividade: Atividade) {
        this.modalRef.close();
        this.modalService.abrirModalAdicionarAtividade(this.processo, atividade, this.options);
    }

    CalcularTotalAberto2() {

        var total = 0;
        this.parcelas.forEach(valor => {
            total += valor.valorAberto2;
        });

        return total;
    }

    valorTotalAcordo: number = 0;
    CalcularValorTotalAcordo(parcela: any) {

        if (parcela.valorTotal != parcela.valorTotal2) {
            parcela.valorAberto2 = parcela.valorTotal2 - (parcela.juros + parcela.multa + parcela.custas + parcela.atualizacaoCusta);
        }

        parcela.alterado = true;

        this.valorTotalAcordo = 0;

        var totalParcelas = this.parcelas.length - 1;
        var totalParcelasRestantes = totalParcelas;

        var valorSobraParcela = 0;

        var valorTotalCalculo = 0;

        this.parcelas.forEach(valor => {

            if (valor.tipoParcela == 'parcela normal') {
                if (valor.alterado) {
                    valorTotalCalculo += valor.valorAberto2;
                    totalParcelasRestantes--;
                }
            }
        });

        this.parcelas.forEach(valor => {

            if (valor.tipoParcela == 'parcela normal') {
                if (!valor.alterado) {
                    var valorCalculo = (this.acordo.totalEmAberto - valorTotalCalculo) / (totalParcelasRestantes);
                    valor.valorAberto2 = Math.floor(valorCalculo * 100) / 100;


                    if (valor.parcela == totalParcelas) {
                        valor.valorAberto2 = this.acordo.totalEmAberto - valorSobraParcela;
                    }

                    valor.valorTotal2 = valor.valorAberto2 + valor.juros + valor.multa + valor.custas + valor.atualizacaoCusta;
                }

                valorSobraParcela += valor.valorAberto2;

                this.valorTotalAcordo += valor.valorTotal2;
            }
        });


        //console.log(this.parcelas);
    }

    arredondarValor(valor: any) {
        return Math.floor(valor * 100) / 100;
    }

    async mockParcela() {
        
        this.valorTotalAcordo = 0;

        this.acordoModel = new Acordo();
        var dividas: ProcessoDivida[] = [];

        var dt = new Date(this.camposSimulacao.dataInicialParcelas.getFullYear(),
            this.camposSimulacao.dataInicialParcelas.getMonth(),
            this.camposSimulacao.dataInicialParcelas.getDate());


        //parametro true é passado quando eu quero incluir o desconto
        this.acordo = await this.calculos(true);
        debugger;
        this.calculo = await this.calculos();
        debugger;
        var valorAbertoSobra = 0;
        var valorJurosSobra = 0;
        var valorMultaSobra = 0;
        var valorCustasSobra = 0;
        var valorAtualizacaoCustasSobra = 0;

        var contadorMes = dt.getMonth();
        let numAno = 0;
        let numMes = 0;

        for (let index = 0; index < this.camposSimulacao.numParcelas; index++) {

            if (this.camposSimulacao.diaFixoOption == 'Sim') {
                dt.setMonth(contadorMes);
                numAno = dt.getFullYear();
                numMes = dt.getMonth();

                if (contadorMes == 1 && this.camposSimulacao.dataInicialParcelas.getDate() > 28) {
                    dt = new Date(numAno, numMes, 28);
                    //console.log("If Fevereiro: ", dt);
                }

                else if (this.camposSimulacao.dataInicialParcelas.getDate() == 31) {
                    var numDt = new Date(numAno, numMes + 1, 0).getDate();
                    dt = new Date(numAno, numMes, numDt);
                    //console.log("Else If dia 31: ", dt);
                }

                else {
                    dt = new Date(numAno, numMes, this.camposSimulacao.dataInicialParcelas.getDate());
                    //console.log("Else Outros Dias: ", dt);
                }
                contadorMes == 11 ? contadorMes = 0 : contadorMes++;
            }


            var dados = {
                tipoParcela: "parcela normal",
                numDoc: (index + 1) + "/" + this.camposSimulacao.numParcelas,
                parcela: index + 1,
                alterado: false,
                emissao: new Date().toLocaleDateString("pt-br"),//data
                vencimento: dt.toLocaleDateString("pt-br"),//data
                valorOriginal: 0,
                valorAberto: this.arredondarValor(this.acordo.totalEmAberto / this.camposSimulacao.numParcelas),
                valorAberto2: 0,
                juros: this.arredondarValor(this.acordo.juros / this.camposSimulacao.numParcelas),
                multa: this.arredondarValor(this.acordo.multa / this.camposSimulacao.numParcelas),
                custas: this.arredondarValor(this.acordo.custas / this.camposSimulacao.numParcelas),
                atualizacaoCusta: this.arredondarValor(this.acordo.atualizacaoCusta / this.camposSimulacao.numParcelas),
                valorTotal: 0,
                valorTotal2: 0,
                lancamento: "a vencer",//data
                tipo: TipoAcordoLabel.get(Number(this.camposSimulacao.tipo))
            };

            if ((index + 1) == this.camposSimulacao.numParcelas) {
                dados.valorAberto = this.acordo.totalEmAberto - valorAbertoSobra;
                dados.juros = this.acordo.juros - valorJurosSobra;
                dados.multa = this.acordo.multa - valorMultaSobra;
                dados.custas = this.acordo.custas - valorCustasSobra;
                dados.atualizacaoCusta = this.acordo.atualizacaoCusta - valorAtualizacaoCustasSobra;
            }

            dados.valorAberto2 = dados.valorAberto;
            dados.valorTotal = dados.valorAberto + dados.juros + dados.multa + dados.custas + dados.atualizacaoCusta;
            dados.valorTotal2 = dados.valorTotal

            valorAbertoSobra += dados.valorAberto;
            valorJurosSobra += dados.juros;
            valorMultaSobra += dados.multa;
            valorCustasSobra += dados.custas;
            valorAtualizacaoCustasSobra += dados.atualizacaoCusta;



            this.parcelas.push(dados);

            this.valorTotalAcordo += (this.acordo.totalEmAberto / this.camposSimulacao.numParcelas) + (this.acordo.juros / this.camposSimulacao.numParcelas) + (this.acordo.multa / this.camposSimulacao.numParcelas) + (this.acordo.custas / this.camposSimulacao.numParcelas) + (this.acordo.atualizacaoCusta / this.camposSimulacao.numParcelas);

            var processoDividaParaAcordo = new ProcessoDivida();
            processoDividaParaAcordo.dataEmissao = new Date();
            processoDividaParaAcordo.dataVencimento = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
            processoDividaParaAcordo.valorOriginal = 0,
                processoDividaParaAcordo.valorAberto = (this.acordo.totalEmAberto) / this.camposSimulacao.numParcelas
            processoDividaParaAcordo.parcela = (index + 1).toString();
            processoDividaParaAcordo.numeroDocumento = (index + 1) + "/" + this.camposSimulacao.numParcelas;
            processoDividaParaAcordo.numeroTitulo = "";
            processoDividaParaAcordo.tipoParcela = "Parcela Comum";
            processoDividaParaAcordo.juros = this.acordo.juros / this.camposSimulacao.numParcelas;
            processoDividaParaAcordo.multa = this.acordo.multa / this.camposSimulacao.numParcelas;
            processoDividaParaAcordo.valorCustasProtesto = this.acordo.custas / this.camposSimulacao.numParcelas;
            processoDividaParaAcordo.valorSaldoPendente = (this.acordo.totalEmAberto / this.camposSimulacao.numParcelas) + (this.acordo.juros / this.camposSimulacao.numParcelas) + (this.acordo.multa / this.camposSimulacao.numParcelas) + (this.acordo.custas / this.camposSimulacao.numParcelas) + (this.acordo.atualizacaoCusta / this.camposSimulacao.numParcelas);


            dividas.push(processoDividaParaAcordo);

            if (this.camposSimulacao.diaFixoOption == 'Sim') {
                dt.setMonth(dt.getMonth() + 1);

            } else {

                dt = this.addDays(dt, this.camposSimulacao.intervaloDias);
                console.log("Else Intervalo de dias: ", dt);
            }

        }

        //linha juros administrativos
        this.parcelas.push({
            tipoParcela: "Taxa administrativa",
            numDoc: this.processo.idExterno,
            emissao: new Date().toLocaleDateString("pt-br"),//data
            vencimento: dt.toLocaleDateString("pt-br"),//data
            valorOriginal: this.acordo.taxaAdministrativa,
            valorAberto: this.acordo.taxaAdministrativa,
            lancamento: "",//data
            tipo: TipoAcordoLabel.get(Number(this.camposSimulacao.tipo))
        });


        this.acordoModel.parcelas = this.camposSimulacao.numParcelas;
        this.acordoModel.dia = this.camposSimulacao.intervaloDias;
        this.acordoModel.diaFixo = this.camposSimulacao.diaFixoOption == "Sim" ? true : false;
        this.acordoModel.inicio = this.camposSimulacao.dataInicialParcelas;
        this.acordoModel.desconto = this.acordo.desconto;
        this.acordoModel.tipo = TipoAcordo[Number(this.camposSimulacao.tipo)];
        this.acordoModel.dividas = dividas;
        this.acordoModel.processoId = this.processo.id;
        this.acordoModel.percentualJuros = this.camposSimulacao.percentualJuros;
        this.acordoModel.juros = this.acordo.juros;
        this.acordoModel.percentualMulta = this.camposSimulacao.percentualMulta;
        this.acordoModel.multa = this.acordo.multa;
        this.acordoModel.percentualTaxaAdministrativa = this.camposSimulacao.percentualTaxaAdministrativa;
        this.acordoModel.percentualAtualizacaoCusta = this.camposSimulacao.percentualAtualizacaoCusta;
        this.acordoModel.taxaAdministrativa = this.acordo.taxaAdministrativa;
        this.acordoModel.protesto = this.acordo.custas;
        this.acordoModel.valorAberto = (this.acordo.totalEmAberto);
        this.acordoModel.valorOriginal = this.acordo.valorOriginal;
        this.acordoModel.valorTotal = (this.acordo.totalEmAberto) + (this.acordo.juros) + (this.acordo.multa) + (this.acordo.custas);
    }

    addDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
    }

    modalSalvarParcelas() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false,

        });


        var formatoValor = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' };


        var mensagemErro = "";
        var totalParcela = 0;
        this.parcelas.forEach(parcela => {
          if (parcela.valorTotal2) {
                if (parcela.valorTotal2 < 0) {
                    mensagemErro = "Os valores não podem ser menor que zero, por favor corrija os valores das parcelas!<br>";
                }

                totalParcela += parcela.valorTotal2;
            }
        });

        this.dividas.forEach(divida => {
          if(divida.situacao.nome == "Pagamento Pendente Revisão" && divida.situacao.nome == "Pagamento Pendente Aprovação" && Math.round(totalParcela) == Math.round(this.acordo.totalEmAberto + this.acordo.juros + this.acordo.multa + this.acordo.custas + this.acordo.atualizacaoCusta)) {
          mensagemErro = "Existem pagamentos pendentes de revisão!<br>";
          }

        });

        if (Math.round(totalParcela) != Math.round(this.acordo.totalEmAberto + this.acordo.juros + this.acordo.multa + this.acordo.custas + this.acordo.atualizacaoCusta)) {
            mensagemErro += "O valor total das parcelas é diferente do valor total a ser pago!<br>";
        }

        if (mensagemErro.length > 0) {
            this.toastrService.error(mensagemErro, 'Atenção', { timeOut: 10000, enableHtml: true });
        } else {

            if (this.atualizarValoresAcordo()) {
                swalWithBootstrapButtons.fire({
                    title: 'Confimar Parcelas ?',
                    html: "<b>Total de Parcelas: </b>  " + this.camposSimulacao.numParcelas
                        + "<br/><br/><b>Valor de juros: </b>" + Number(this.acordo.juros).toLocaleString('pt-br', formatoValor)
                        + "<br/><br/><b>Valor da multa: </b>" + Number(this.acordo.multa).toLocaleString('pt-br', formatoValor)
                        + "<br/><br/><b>Valor aberto: </b>" + Number(this.acordo.totalEmAberto).toLocaleString('pt-br', formatoValor)
                        + "<br/><br/><b>Valor total a pagar: </b>" + Number(this.acordo.totalEmAberto + this.acordo.multa + this.acordo.juros + this.acordo.custas + this.acordo.atualizacaoCusta).toLocaleString('pt-br', formatoValor),

                    confirmButtonColor: '#FFD950 !important;',
                    showCloseButton: true,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar',
                    target: document.getElementById('modal')

                }).then((result) => {

                    if (result.value === true) {
                        this.salvarAcordo();
                    }
                });
            }
        }
    }

    async attCalculo(event, field) {

        this.calculo = await this.calculos();
        this.acordo = await this.calculos(true);
    }

    getTotalValorAbertoDividasVencidas(divida) {

        var dt = new Date(this.camposSimulacao.dataCalculos);

        var totalValorAberto = 0;

        if (new Date(divida.dataVencimento) < dt) {
            totalValorAberto = divida.valorAberto;
        }

        return totalValorAberto;
    }

    onSelectDate(evt: any) {
        this.camposSimulacao.dataInicialParcelas = new Date(evt.year, evt.month - 1, evt.day);
    }


    async handleChange(evt, tipo) {
        var target = evt.target;


        if (tipo == 'simples') {
            this.camposSimulacao.simples = target.checked;
            this.camposSimulacao.composto = false;
        }
        if (tipo == 'composto') {
            this.camposSimulacao.composto = target.checked;
            this.camposSimulacao.simples = false;
        }

        this.calculo = await this.calculos();
        this.acordo = await this.calculos(true);

    }

    getDifferenceInDays(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        return diffInMs / (1000 * 60 * 60 * 24);
    }
    getDifferenceInMonths(dt2, dt1) {
        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60 * 24 * 7 * 4);
        return Math.abs(Math.round(diff));
    }
    getDescontoOuAcrescimo(valorOriginalProcesso) {
        var desconto = this.camposSimulacao.porcentagemOuValorOption == "%" ? valorOriginalProcesso * (this.camposSimulacao.porcentagemOuValor / 100) : this.camposSimulacao.porcentagemOuValor;
        if (this.camposSimulacao.acrescimoOuDesconto == 'Desconto') {
            return -desconto;
        } else {
            return desconto;
        }
    }

    atualizarValoresAcordo() {

        this.acordoModel.dividas.forEach(divida => {

            var parcela = this.parcelas.filter(x => x.numDoc == divida.numeroDocumento);
            divida.juros = parcela[0].juros;
            divida.multa = parcela[0].multa;
            divida.valorCustasProtesto = parcela[0].custas;
            divida.valorAtualizacaoCustas = parcela[0].atualizacaoCusta;
            divida.valorAberto = parcela[0].valorAberto2;
            divida.valorSaldoPendente = parcela[0].valorTotal2;
        });

        return true;

    }

    salvarAcordo() {
        this.spinner.show();
        this.toastrService.clear();

        this.acordoService.isEnviarAprovacaoCredor(this.acordoModel).subscribe(res => {

            this.spinner.hide();
                    console.log(this.processo);
                    console.log(this.processo);
            if (res.data) {
                var resultado = this.modalService.abrirModalEmailCredor(this.processo.credorId, true);
                resultado.asObservable().subscribe(res => {
                    if (res != undefined) {
                        if (res.result === true && res.page === 'emailCredor' && this.acordoModel) {
                            this.acordoModel.emails = res.lista;
                            this.acordoModel.descricao = res.description
                            this.salvarAcordoCore();
                        }
                    }
                });
            } else {
                this.salvarAcordoCore();
            }
        }, erro => {
            this.toastrService.error("Erro ao gerar acordo!", "Erro")
        });
    }

    private salvarAcordoCore() {
        debugger;
        this.spinner.show();
        this.toastrService.clear();
        
        this.acordoModel.justificativa = this.justificativa;
        
        this.acordoService.add(this.acordoModel).subscribe(x => {

            //se os ids forem diferentes,significa que um novo processo foi criado
            if (x["data"].ocorrencia.processoId != this.processo.id) {
                //avisar que um processo novo foi criado
                this.modalNovoProcessoFoiCriado(x["data"].ocorrencia.processoId);
            }

            this.parcelas = [];
            this.getAllData();
            this.toastrService.success("Acordo gerado!", "Sucesso")
            this.acordoModel = null;
        }, erro => {
            this.toastrService.error("Erro ao gerar acordo!", "Erro")
        },

        ).add(() => this.spinner.hide());
    }

    checkarStatusDivida() {
        this.dividaL.forEach(divida => {
            var acordoDivida = this.acordosL.find(x => x.ocorrenciaId == divida.acordoId);
            if (acordoDivida != null) {
                divida.statusAcordo = acordoDivida.ocorrencia.situacao;
            } else {
                divida.statusAcordo = null;
            }
        });
    }

    detalheOcorrencia(ocorrencia: Ocorrencia) {

        let tipo = TipoOcorrencia[ocorrencia.tipo.toString()];

        if (tipo == TipoOcorrencia[TipoOcorrencia.Ocorrencia]) {
            this.modalRef.close();

            if (ocorrencia.criadoPor == this.authService.getUserName() && (!ocorrencia.isSistemaSituacao)) {
                this.editarOcorrencia(ocorrencia);
            }
            else {
                this.modalService.abrirModalDetalheOcorrencia(this.processo, ocorrencia, this.options);
            }
        } else if (tipo == TipoOcorrencia[TipoOcorrencia.Atividade]) {
            let atividade = new Atividade();
            atividade.ocorrenciaId = ocorrencia.id;

            this.editarAtividade(atividade);
        } else if (tipo == TipoOcorrencia[TipoOcorrencia.QuitacaoDivida]) {
            this.modalRef.close();

            this.abrirModalAprovarDividaPagamento(this.processo, ocorrencia);

        } else if (tipo == TipoOcorrencia[TipoOcorrencia.DescontoDivida]) {

            this.modalRef.close();

            this.abrirModalAprovarDividaDesconto(this.processo, ocorrencia);

        } else if (tipo == TipoOcorrencia[TipoOcorrencia.Acordo]) {
            this.modalRef.close();
            this.abrirModalAprovarAcordo(this.processo, ocorrencia);

        } else if (tipo == TipoOcorrencia[TipoOcorrencia.Notificacao]) {

            this.modalRef.close();
            this.modalService.abrirModalDetalheOcorrencia(this.processo, ocorrencia, this.options);

        } else if (tipo == TipoOcorrencia[TipoOcorrencia.ProcessoRedirecionamento]) {

            this.modalRef.close();
            this.modalService.abrirModalHistoricoRedirecionamento(this.processo.id);
        }
    }

    detalhePessoa(id) {

        this.pessoaService.getById(id).subscribe((res) => {
            // var pessoParaExibicao = this.pessoaService.adaptarPessoaDadosParaExibicao(res.data);

            this.modalService.abrirModalCadastraPessoa(res.data, true);
        });
    }

    getStatusLabel(status: TipoStatus) {
        return TipoStatusLabel.get(status);
    }

    abrirModalAprovarAcordo(processo: ProcessoOperacao, ocorrencia: Ocorrencia) {
        const modalRef = this.ngbModal.open(ModalAprovarAcordoComponent
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'AprovarAcordo';
        modalRef.componentInstance.processo = processo;
        modalRef.componentInstance.ocorrencia = ocorrencia;
        modalRef.componentInstance.options = this.options;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
            this.getAllData();
        }).catch(error => { });
    }

    getClassByTipo(ocorrencia: Ocorrencia) {
        let tipo = TipoOcorrencia[ocorrencia.tipo.toString()];

        if (tipo == TipoOcorrencia[TipoOcorrencia.Ocorrencia]) {
            return "text-light";
        }

        if (tipo == TipoOcorrencia[TipoOcorrencia.Atividade]) {
            return "text-primary";
        }

        if (tipo == TipoOcorrencia[TipoOcorrencia.Acordo]) {
            return "text-success";
        }
        if (tipo == TipoOcorrencia[TipoOcorrencia.QuitacaoDivida]) {
            return "text-info";
        }

    }

    getTipoLabel(tipo: TipoOcorrencia) {
        return TipoOcorrenciaLabel.get(tipo);
    }

    abrirModalAprovarDividaDesconto(processo: ProcessoOperacao, ocorrencia: Ocorrencia) {
        const modalRef = this.ngbModal.open(ModalAprovarDividaDescontoComponent
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'AprovarDividaDesconto';
        modalRef.componentInstance.processo = processo;
        modalRef.componentInstance.ocorrencia = ocorrencia;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    gerarDescumprimentoAcordo(acordoId) {
        this.spinner.show();
        this.toastrService.clear();
        this.acordoService.gerarDescumprimentoAcordo(acordoId).subscribe(x => {
            //gerou
            this.getAllData();
            this.toastrService.success("Descumpirmento Gerado!", "Sucesso")
            this.spinner.hide();
        }, erro => {
            this.toastrService.error("Erro ao gerar descumprimento!", "Erro")
            this.spinner.hide();
        })
    }


    checkColorBadgeSituacaoAcordo(acordo) {
        var guid = Guid.parse(acordo.ocorrencia.situacaoId);

        //aprovado verde
        if (guid.toString() == this.parametroL.filter(x => x.chave.toString() == ParametroChaveType[ParametroChaveType.SITUACAO_ACORDO_FILHO_APROVADO].toString())[0].valor) {
            return "badge-success";
        }
        //reprovado vermelho
        if (guid.toString() == this.parametroL.filter(x => x.chave.toString() == ParametroChaveType[ParametroChaveType.SITUACAO_ACORDO_FILHO_NEGADO].toString())[0].valor) {
            return "badge-danger";
        }
        //em analise amarelo
        if (guid.toString() == this.parametroL.filter(x => x.chave.toString() == ParametroChaveType[ParametroChaveType.SITUACAO_ACORDO_FILHO_ANALISE].toString())[0].valor) {
            return "badge-warning";
        }

        return "badge-primary";
    }

    checkDescumprimentoButton(acordo) {
        //botao de descumprimento aparece em duas hipoteses:
        //1-quando o acordo ja foi aprovado  2- quando o descumprimento foi reprovado

        var guid = Guid.parse(acordo.ocorrencia.situacaoId);

        if (guid.toString() == Guid.parse(this.parametroL.filter(x => x.chave.toString() == ParametroChaveType[ParametroChaveType.SITUACAO_ACORDO_FILHO_APROVADO].toString())[0].valor).toString() ||
            guid.toString() == Guid.parse(this.parametroL.filter(x => x.chave.toString() == ParametroChaveType[ParametroChaveType.SITUACAO_ACORDO_FILHO_DESCUMPRIMENTO_NEGADO].toString())[0].valor).toString()) {
            return true
        } else {
            return false;
        }
    }

    checkButtonCriarParcelas() {
        var teste = "";
        //o botao de criar um novo acordo aparace apenas da hipotese de nenhum acordo estiver vigente e se nenhum estiver em analise

        for (let x of this.acordosL) {

            var guid = Guid.parse(x.ocorrencia.situacaoId.toString());

            if (guid.toString() == Guid.parse(this.parametroL.filter(x => x.chave.toString() == ParametroChaveType[ParametroChaveType.SITUACAO_ACORDO_FILHO_APROVADO].toString())[0].valor).toString()) {
                return false;
            }
            if (guid.toString() == Guid.parse(this.parametroL.filter(x => x.chave.toString() == ParametroChaveType[ParametroChaveType.SITUACAO_ACORDO_FILHO_ANALISE].toString())[0].valor).toString()) {
                return false;
            }
        }
        return true;
    }

    abrirModalAprovarDividaPagamento(processo: ProcessoOperacao, ocorrencia: Ocorrencia) {
        const modalRef = this.ngbModal.open(ModalAprovarDividaPagamentoComponent
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });
        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.page = 'AprovarDividaPagamento';
        modalRef.componentInstance.processo = processo;
        modalRef.componentInstance.ocorrencia = ocorrencia;
        modalRef.componentInstance.options = this.options;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
        }).catch(error => { });
    }

    private filterDivida(tipo: number) {
        var dividaResultado: ProcessoDivida[] = [];

        if (this.dividaL) {
            if (tipo == 0) // Geral
            {
                dividaResultado = this.dividaL.filter(x => x.tipoParcela != 'taxa administrativa' && x.acordoId == null);
            }
            else if (tipo == 1) // Pagamento
            {            
              let isAcordo = (this.dividaL.find(x => x.acordoId != null) != undefined);
              
              if( isAcordo ){
                  dividaResultado = this.dividaL.filter(x => x.valorSaldoPendente > 0 && x.tipoParcela != 'taxa administrativa' && x.acordoId != null && x.situacaoAcordo == 'Aprovado');
              }else{
                  dividaResultado = this.dividaL.filter(x => x.valorSaldoPendente > 0 && x.tipoParcela != 'taxa administrativa');
              }
            }
            else if (tipo == 2) // Desconto
            {
                var dividas = this.dividaL.filter(x => x.dividaPaiId == null && x.acordoId == null && x.valorSaldoPendente > 0);

                dividaResultado.length = 0;
                dividas.forEach(y => {
                    if (this.dividaL.filter(x => x.dividaPaiId == y.id && x.id != y.id).length == 0) {
                        dividaResultado.push(y);
                    }

                });
            }
            else if (tipo == 3) // Taxa administrativa
            {
                dividaResultado = this.dividaL.filter(x => x.tipoParcela == 'taxa administrativa' && x.pagamentos != null && x.pagamentos.length > 0);

            }
            else if (tipo == 4) // Acordos
            {
                dividaResultado = this.dividaL.filter(x => x.acordoId != null).sort((a,b)=> {return a.acordoIdExterno - b.acordoIdExterno});
                
                console.log(dividaResultado);
            }

        }

        return dividaResultado;
    }

    selecionarTipoDividas(tipo: number) {

        var dividaResultado = this.filterDivida(tipo);

        this.dividas = dividaResultado;

        this.dividas.forEach(x => {
            x.SelecaoManual = false;
        });

        return dividaResultado;
    }

    modalGerarDescumprimento(acordoId) {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false,

        });

        swalWithBootstrapButtons.fire({
            title: 'Gerar Descumprimento do acordo ?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            target: document.getElementById('modal')

        }).then((result) => {

            if (result.value === true) {
                this.gerarDescumprimentoAcordo(acordoId);
            }
        });

    }

    modalNovoProcessoFoiCriado(processoId) {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false,

        });

        swalWithBootstrapButtons.fire({
            title: 'Um novo processo foi criado !',
            text: 'Um novo processo foi criado com as dividas originais nao pagas e as novas parcelas do acordo, vamos te redirecionar para este processo',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: false,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            target: document.getElementById('modal'),

        }).then((result) => {
            this.abrirModalProcesso(processoId);
        });
    }

    moveElementArray(input, from, to) {
        let numberOfDeletedElm = 1;

        const elm = input.splice(from, numberOfDeletedElm)[0];

        numberOfDeletedElm = 0;

        input.splice(to, numberOfDeletedElm, elm);
    }


    getIdsExternosProcessoOriginalProcessoAcordo() {
        var procAcordo = this.processoOriginalProcessoAcordoL.find(x => x.processoAcordoId == this.processo.id);
        var procOriginal = this.processoOriginalProcessoAcordoL.find(x => x.processoOriginalId == this.processo.id);

        if (procAcordo != null) {
            //pegar original
            this.idExternoProcessoOriginal = {
                idExterno: procAcordo.idExternoOriginal,
                id: procAcordo.processoOriginalId
            };
        }
        if (procOriginal != null) {
            //pegar acordo
            this.idExternoProcessoAcordo = {
                idExterno: procOriginal.idExternoAcordo,
                id: procOriginal.processoAcordoId
            }
        }

    }

    abrirModalProcesso(processoId) {
        this.modalRef.close();
        var proc = new ProcessoOperacao();
        proc.id = processoId;
        this.modalService.abrirModalContatoProcesso(proc, { previousTab: "tabOcorrencias" });
    }


    getQtdDividasOriginais(dividas) {
        var dividas = dividas.filter(x => x.acordoId == null && x.dividaPaiId == null);

        if (dividas != null) {
            return dividas.length;
        }

        return 0;
    }


    getTaxaAdministrativa(dividas) {
        var taxa = 0;

        var dividasOriginais = dividas.filter(function filterDividaAcordo(element, index, array) {
            return (element.acordoId == null && element.dividaPaiId == null);
        });

        dividasOriginais.forEach(divida => {


            if (new Date(divida.dataVencimento) < new Date()) {
                taxa += (divida.valorAberto + divida.juros + divida.multa + divida.valorCustasProtesto + (divida.valorAtualizacaoCustas != null ? divida.valorAtualizacaoCustas : 0)) * (this.processo.percentualTaxaAdministrativa / 100);
            }

        });

        return taxa;
    }


    dividasToExcel() {
        var filename = "dividas.xlsx";

        /* table id is passed over here */
        let element = document.getElementById('table-dividas');
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'mm/dd/yyyy;@', cellDates: false, raw: true });

        let reflinhas = ws['!ref'].split(':')[1];
        let conutlinhas = new Number(reflinhas.substring(1, reflinhas.length));
        for (let i = 2; i <= conutlinhas; i++) {
            ws["I" + i].v = ws["I" + i].v.replace('R$', '').replace('.', '').replace(',', '.').replace(' ', '');
            ws["I" + i].z = 'R$0.00';
            ws["I" + i].t = 'n';
            XLSX.utils.format_cell(ws["I" + i]);
            // console.log(ws["I"+i]);

            ws["J" + i].v = ws["J" + i].v.replace('R$', '').replace('.', '').replace(',', '.').replace(' ', '');
            ws["J" + i].z = 'R$0.00';
            ws["J" + i].t = 'n';
            XLSX.utils.format_cell(ws["J" + i]);
            // console.log(ws["J"+i]);

            ws["K" + i].v = ws["K" + i].v.replace('R$', '').replace('.', '').replace(',', '.').replace(' ', '');
            ws["K" + i].z = 'R$0.00';
            ws["K" + i].t = 'n';
            XLSX.utils.format_cell(ws["K" + i]);
            // console.log(ws["K"+i]);

            ws["L" + i].v = ws["L" + i].v.replace('R$', '').replace('.', '').replace(',', '.').replace(' ', '');
            ws["L" + i].z = 'R$0.00';
            ws["L" + i].t = 'n';
            XLSX.utils.format_cell(ws["L" + i]);
            // console.log(ws["L"+i]);

            ws["M" + i].v = ws["M" + i].v.replace('R$', '').replace('.', '').replace(',', '.').replace(' ', '');
            ws["M" + i].z = 'R$0.00';
            ws["M" + i].t = 'n';
            XLSX.utils.format_cell(ws["M" + i]);
            // console.log(ws["M"+i]);

            ws["N" + i].v = ws["N" + i].v.replace('R$', '').replace('.', '').replace(',', '.').replace(' ', '');
            ws["N" + i].z = 'R$0.00';
            ws["N" + i].t = 'n';
            XLSX.utils.format_cell(ws["N" + i]);
            // console.log(ws["N"+i]);

            ws["O" + i].v = ws["O" + i].v.replace('R$', '').replace('.', '').replace(',', '.').replace(' ', '');
            ws["O" + i].z = 'R$0.00';
            ws["O" + i].t = 'n';
            XLSX.utils.format_cell(ws["O" + i]);
            // console.log(ws["O"+i]);

            ws["P" + i].v = ws["P" + i].v.replace('R$', '').replace('.', '').replace(',', '.').replace(' ', '');
            ws["P" + i].z = 'R$0.00';
            ws["P" + i].t = 'n';
            XLSX.utils.format_cell(ws["P" + i]);
            // console.log(ws["P"+i]);

            ws["Q" + i].v = ws["Q" + i].v.replace('R$', '').replace('.', '').replace(',', '.').replace(' ', '');
            ws["Q" + i].z = 'R$0.00';
            ws["Q" + i].t = 'n';
            XLSX.utils.format_cell(ws["Q" + i]);
            // console.log(ws["Q"+i]);

            ws["R" + i].v = ws["R" + i].v.replace('R$', '').replace('.', '').replace(',', '.').replace(' ', '');
            ws["R" + i].z = 'R$0.00';
            ws["R" + i].t = 'n';
            XLSX.utils.format_cell(ws["R" + i]);
            // console.log(ws["R"+i]);
        }

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, filename);
    }

    removeWhiteSpace(str) {
        str = str.replace(/\s/g, "");
        return str;
    }

    selectORDeselectAllDividas(dividas, check) {
        dividas.forEach(div => {
            if (div.situacao.nome == 'Novo' || div.situacao.nome == 'Desconto Aprovado') {
                div.selecionado = check;
                this.calcularValorPagamento({ target: { checked: check } }, div);
            }
        });

        this.selecteAllDividasPagamento = check;
    }

    recalculoDividasSwitch(dividas) {
        dividas.forEach(div => {
            if (div.situacao.nome == 'Novo' || div.situacao.nome == 'Desconto Aprovado') {
                this.calcularValorPagamento({ target: { checked: div.selecionado } }, div);
            }
        });
    }


    atualizarPercentuaisBaseadosNaSimulacao() {
        this.spinner.show();
        var processoId = this.processo.id;
        var data = {
            percentualJuros: this.camposSimulacao.percentualJuros,
            percentualMulta: this.camposSimulacao.percentualMulta,
            percentualTaxaAdministrativa: this.camposSimulacao.percentualTaxaAdministrativa,
            dataReferencia: this.camposSimulacao.dataCalculos
        };

        this.processoService.updatePercentuaisProcesso(processoId, data).subscribe(x => {
            this.spinner.hide();
            this.getAllData();
        }, error => {

            this.modalAlertaPercentuaisNaoBatemComAPoliticaDoCredor(error.errors)

            this.spinner.hide();
        });
    }


    modalAtualizarPercentuaisDoProcesso() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false,

        });

        swalWithBootstrapButtons.fire({
            title: 'Atualizar Percentuais ?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            text: 'Esse processo irá atualizar os percentuais do processo, assim atualizando as dividas',
            target: document.getElementById('modal')

        }).then((result) => {

            if (result.value === true) {
                this.atualizarPercentuaisBaseadosNaSimulacao();
            }
        });
    }

    modalAlertaPercentuaisNaoBatemComAPoliticaDoCredor(erros) {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons.fire({
            title: 'Alerta',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            confirmButtonText: 'Confirmar',
            text: erros[0],
            target: document.getElementById('modal')

        }).then((result) => {
        });
    }


    modalExcluirTitulo(divida) {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false,

        });

        swalWithBootstrapButtons.fire({
            title: 'Quer mesmo excluir esse titulo ?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            text: 'A exclusão deste titulo não podera ser desfeita, quer mesmo prosseguir ?',
            target: document.getElementById('modal')

        }).then((result) => {

            if (result.value === true) {
                this.excluirTitulo(divida.id);
            }
        });
    }

    excluirTitulo(id) {
        this.toastrService.clear();
        this.spinner.show();
        this.dividaService.delete(id).subscribe(x => {
            this.toastrService.success("Exclusão bem sucedida", "Sucesso")
            this.getAllData();
            this.getDividasEAcordos();
            this.spinner.hide();
        }, error => {

            this.toastrService.error(error.errors, "Erro")
            this.spinner.hide();
        })
    }



    modalExcluirTaxaAdministrativa(taxa) {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false,

        });

        swalWithBootstrapButtons.fire({
            title: 'Quer mesmo excluir essa taxa administrativa ?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            text: 'A exclusão desta taxa não podera ser desfeita, quer mesmo prosseguir ?',
            target: document.getElementById('modal')

        }).then((result) => {

            if (result.value === true) {
                this.excluirTaxaAdministrativa(taxa.pagamentos[0].id);
            }
        });
    }

    excluirTaxaAdministrativa(id) {
        this.toastrService.clear();
        this.spinner.show();
        this.dividaPagamentoService.deleteTAxaAdministrativa(id).subscribe(x => {
            this.toastrService.success("Exclusão bem sucedida", "Sucesso")
            this.getDividasEAcordos();
            this.spinner.hide();
        }, error => {

            this.toastrService.error(error.errors, "Erro")
            this.spinner.hide();
        })
    }



    modalExcluirArquivo(arquivo) {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false,

        });

        swalWithBootstrapButtons.fire({
            title: 'Quer mesmo excluir esse arquivo ?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            text: 'A exclusão deste arquivo não podera ser desfeita, quer mesmo prosseguir ?',
            target: document.getElementById('modal')

        }).then((result) => {

            if (result.value === true) {
                this.excluirArquivo(arquivo);
            }
        });
    }

    excluirArquivo(arquivo) {
        this.toastrService.clear();
        this.spinner.show();

        var isArquivoOcorrencia: Boolean = this.isArquivoOcorrencia(arquivo);

        //arquivo gerado diretamento no processo
        if (!isArquivoOcorrencia) {
            this.processoService.deleteArquivo(this.processo.id, arquivo.id).subscribe(x => {
                this.toastrService.success("Exclusão bem sucedida", "Sucesso")
                this.getAllData();
                this.spinner.hide();
            }, error => {
                this.toastrService.error(error.errors, "Erro")
                this.spinner.hide();
            })
        }

        //arquivo gerado por ocorrencia
        if (isArquivoOcorrencia) {
            this.ocorrenciaService.deleteArquivo(arquivo.ocorrenciaId, arquivo.id).subscribe(x => {
                this.toastrService.success("Exclusão bem sucedida", "Sucesso")
                this.getAllData();
                this.spinner.hide();
            }, error => {
                this.toastrService.error(error.errors, "Erro")
                this.spinner.hide();
            })
        }


    }
    loadExtrato: boolean = false;
    
    selecionarExtrato(){
        this.loadExtrato = true;
        this.dividaService.getProcessoDividaDetalheAllByProcesso(this.processo.id).subscribe(async (res: ProcessoDividaResponse) => {
            this.dividasExtrato = res.data;
            
            this.dividasExtrato.forEach(divida => {
                divida.expandido = false;
            });

            console.log(this.dividasExtrato);

            this.loadExtrato = false;
        });
    }

    expandirDividaExtrato(divida:any){
        divida.expandido = !divida.expandido;
    }

    obterDataPagamento(acordo){
        if(acordo.pagamentos != undefined &&  acordo.pagamentos != null && acordo.pagamentos.length > 0){
            return acordo.pagamentos[0].dataPagamento;
        }

        return "";
    }
}
