<h4 class=" d-flex justify-content-between align-items-center font-weight-bold pt-3">
    <span><span class="text-muted font-weight-light">Financeiro /</span> Gestão Acordos Judiciais</span>
</h4>
<hr class="border-light my-0">

<div class="filtro-header mt-2">
    <div class="p-3 filtro-header-titulo d-flex justify-content-between" (click)="abrirFecharFiltro()">
        <span><i style="margin-left: 10px;" *ngIf="loadAcordosEmAberto" class="spinner-border ml-auto spinner-border-sm"
                role="status" aria-hidden="true"></i> Filtros</span>
        <span class="lnr lnr-chevron-up" *ngIf="filtroOn"></span>
        <span class="lnr lnr-chevron-down" *ngIf="!filtroOn"></span>
    </div>
    <div class="filtro-details" *ngIf="filtroOn">
        <div class="form-row align-items-center mb-3">
            <div class="col-md-1 col-sm-1">
                <label class="form-label">Código Acordo</label>
                <input type="text" [(ngModel)]="filtro.codigoAcordo" class="form-control">
            </div>
            <div class="col-md-1 col-sm-1">
                <label class="form-label">Pasta</label>
                <input type="text" [(ngModel)]="filtro.pasta" class="form-control">
            </div>
            <div class="col-md-4 col-sm-2">
                <label class="form-label">Número Processo</label>
                <input type="text" [(ngModel)]="filtro.numeroProcesso" class="form-control">
            </div>
            <div class="col-md-3 col-sm-4">
                <label class="form-label">Cliente</label>
                <pessoa-dropdown [(pessoaId)]="filtro.cliente" [selecionaMultiplos]="false"></pessoa-dropdown>
            </div>
            <div class="col-md-3 col-sm-4">
                <label class="form-label">Envolvido</label>
                <pessoa-dropdown [(pessoaId)]="filtro.envolvido" [selecionaMultiplos]="false"></pessoa-dropdown>
            </div>
        </div>
        <div class="form-row align-items-center mb-3">
            <div class="form-check form-check-inline form-group mt-1">
                <input mdbRadio name="tipoAcordo" class="form-check-input" type="radio"
                    [(ngModel)]="filtro.minutaDeAcordo" id="acordo_vinculado" value="1" />
                <label class="form-check-label" for="acordo_vinculado">Acordo Principal</label>
            </div>
            <div class="form-check form-check-inline form-group mt-1">
                <input mdbRadio name="tipoAcordo" class="form-check-input" type="radio"
                    [(ngModel)]="filtro.minutaDeAcordo" id="acordo_avulso" value="2"
                    (change)="limparAcordoVinculado()" />
                <label class="form-check-label" for="acordo_avulso">Acordo de Honorários</label>
            </div>
            <div class="form-check form-check-inline form-group mt-1">
                <input mdbRadio name="tipoAcordo" class="form-check-input" type="radio"
                    [(ngModel)]="filtro.minutaDeAcordo" id="acordo_todos" value="0"
                    (change)="limparAcordoVinculado()" />
                <label class="form-check-label" for="acordo_todos">Todos</label>
            </div>
        </div>
        <div class="form-row align-items-center mb-3">
            <div class="col-md-8 col-sm-4">

            </div>
            <div class="col-md-2 col-sm-4">
                <label class="form-label d-none d-md-block">&nbsp;</label>
                    <button type="button" class="btn btn-padrao btn-block" (click)="filtrar()"><span
                        class="fas fa-search"></span>&nbsp;&nbsp;Filtrar</button>
            </div>
            <div class="col-md-2 col-sm-4">
                <label class="form-label d-none d-md-block">&nbsp;</label>
                    <button type="button" class="btn bg-light btn-block" (click)="limparFiltros()"><span
                        class="fas fa-search"></span>&nbsp;&nbsp;Limpar</button>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <ul ngbNav #acordosTabsNova="ngbNav" class="nav-tabs">
            <li ngbNavItem [ngbNavItem]="1">
                <a ngbNavLink (click)="carregarAcordos()">Por Acordo</a>
                <ng-template ngbNavContent>
                    <ul ngbNav #porAcordoTabs="ngbNav" class="nav-tabs">
                        <li ngbNavItem [ngbNavItem]="1">
                            <a ngbNavLink>Pendentes</a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="text-center mt-5"
                                        *ngIf="!loadAcordosEmAberto && acordosParcelasEmAberto.length == 0">
                                        <h3>Não existem acordos pendentes</h3>
                                    </div>
                                    <div class="table-responsive"
                                        *ngIf="!loadAcordosEmAberto && acordosParcelasEmAberto.length > 0">
                                        <table class="table table-striped table-bordered card-table">
                                            <thead>
                                                <tr>
                                                    <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('idExterno')">
                                                        Código Acordo<i class="ion text-muted ml-2"
                                                            *ngIf="sortByPendentes =='idExterno'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('pasta')">
                                                        Pasta<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='pasta'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentesPorParcela('numeroProcesso')">
                                                        Número Processo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='numeroProcesso'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('razao')">
                                                        Cliente<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='razao'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('devedor')">
                                                        Envolvido<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='devedor'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('situacaoAcordo')">
                                                        Situação do Acordo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='situacaoAcordo'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('tipoAcordoDisplay')">
                                                        Tipo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='tipoAcordoDisplay'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('valorPrincipal')">
                                                        Valor Principal<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>                                        
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('valorPago')">
                                                        Valores Pagos<i class="ion text-muted ml-2"
                                                            *ngIf="sortByPendentes =='valorPago'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('saldo')">
                                                      Saldo<i class="ion text-muted ml-2"
                                                        *ngIf="sortByPendentes =='saldo'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
            
                                                    <th class="text-center text-nowrap align-middle">Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let acordo of acordosParcelasEmAberto">
                                                    <td class="text-center">{{acordo.idExterno}}</td>
            
                                                    <td class="text-center">{{acordo.pasta}}</td>
                                                    <td class="text-center">{{acordo.numeroProcesso}}
                                                      <a class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true"
                                                          href="{{raiz}}/processos-judiciais/detalhes/{{acordo.idProcesso}}" target="_blank"></a></td>
                                                    <td class="text-center">{{acordo.razao}}</td>
                                                    <td class="text-center">{{acordo.devedor}}</td>
                                                    <td class="text-center">{{acordo.situacaoAcordo}}</td>
                                                    <td class="text-center">{{acordo.tipoAcordoDisplay}}</td>
                                                    <td class="text-center">{{acordo.valorPrincipal | currency: 'BRL'}}</td>                                        
                                                    <td class="text-center">{{acordo.valorPago | currency: 'BRL'}}</td>
                                                    <td class="text-center">{{acordo.saldo | currency: 'BRL'}}</td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-primary btn-sm" (click)="abrirModalAcordo(acordo)">
                                                            <i class="fa fa-plus-circle"></i> Detalhes
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem [ngbNavItem]="2">
                            <a ngbNavLink>Vencem Hoje</a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="text-center mt-5"
                                        *ngIf="!loadAcordosVencemHoje && acordosParcelasVencemHoje.length == 0">
                                        <h3>Não existem acordos vencendo hoje</h3>
                                    </div>
                                    <div class="table-responsive"
                                        *ngIf="!loadAcordosVencemHoje && acordosParcelasVencemHoje.length > 0">
                                        <table class="table table-striped table-bordered card-table">
                                            <thead>
                                                <tr>
                                                  <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('idExterno')">
                                                    ID Acordo<i class="ion text-muted ml-2"
                                                        *ngIf="sortByPendentes =='idExterno'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('pasta')">
                                                    Pasta<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='pasta'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('numeroProcesso')">
                                                    Numero Processo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='numeroProcesso'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('razao')">
                                                    Cliente<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='razao'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('devedor')">
                                                    Envolvido<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='devedor'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('situacaoAcordo')">
                                                    Situação do Acordo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='situacaoAcordo'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('tipoAcordoDisplay')">
                                                    Tipo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='tipoAcordoDisplay'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('valorPrincipal')">
                                                    Valor Principal<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>                                    
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('valorPago')">
                                                    Valores Pagos<i class="ion text-muted ml-2"
                                                        *ngIf="sortByPendentes =='valorPago'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                (click)="setSortPendentes('saldo')">
                                                  Saldo<i class="ion text-muted ml-2"
                                                    *ngIf="sortByPendentes =='saldo'"
                                                    [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                            </th>
            
                                                <th class="text-center text-nowrap align-middle">Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let acordo of acordosParcelasVencemHoje">
                                                  <td class="text-center">{{acordo.idExterno}}</td>
            
                                                  <td class="text-center">{{acordo.pasta}}</td>
                                                  <td class="text-center">{{acordo.numeroProcesso}}
                                                    <a class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true"
                                                        href="{{raiz}}/processos-judiciais/detalhes/{{acordo.idProcesso}}" target="_blank"></a></td>
                                                  <td class="text-center">{{acordo.razao}}</td>
                                                  <td class="text-center">{{acordo.devedor}}</td>
                                                  <td class="text-center">{{acordo.situacaoAcordo}}</td>
                                                  <td class="text-center">{{acordo.tipoAcordoDisplay}}</td>
                                                  <td class="text-center">{{acordo.valorPrincipal}}</td>
                                                  <td class="text-center">{{acordo.valorPago}}</td>
                                                  <td class="text-center">{{acordo.saldo}}</td>
                                                  <td class="text-center">
                                                      <button type="button" class="btn btn-primary btn-sm" (click)="abrirModalAcordo(acordo)">
                                                          <i class="fa fa-plus-circle"></i> Detalhes
                                                      </button>
                                                  </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem [ngbNavItem]="3">
                            <a ngbNavLink>Vencidas</a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="text-center mt-5"
                                        *ngIf="!loadAcordosVencidas && acordosParcelasVencidas.length == 0">
                                        <h3>Não existem acordos vencidos</h3>
                                    </div>
                                    <div class="table-responsive"
                                        *ngIf="!loadAcordosVencidas && acordosParcelasVencidas.length > 0">
                                        <table class="table table-striped table-bordered card-table">
                                            <thead>
                                                <tr>
                                                  <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('idExterno')">
                                                    ID Acordo<i class="ion text-muted ml-2"
                                                        *ngIf="sortByPendentes =='idExterno'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('pasta')">
                                                    Pasta<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='pasta'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('numeroProcesso')">
                                                    Numero Processo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='numeroProcesso'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('razao')">
                                                    Cliente<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='razao'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('devedor')">
                                                    Envolvido<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='devedor'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('situacaoAcordo')">
                                                    Situação do Acordo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='situacaoAcordo'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('tipoAcordoDisplay')">
                                                    Tipo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='tipoAcordoDisplay'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('valorPrincipal')">
                                                    Valor Principal<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>                                    
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('valorPago')">
                                                    Valores Pagos<i class="ion text-muted ml-2"
                                                        *ngIf="sortByPendentes =='valorPago'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                (click)="setSortPendentes('saldo')">
                                                  Saldo<i class="ion text-muted ml-2"
                                                    *ngIf="sortByPendentes =='saldo'"
                                                    [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                            </th>
            
                                                <th class="text-center text-nowrap align-middle">Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let acordo of acordosParcelasVencidas">
                                                  <td class="text-center">{{acordo.idExterno}}</td>
            
                                                  <td class="text-center">{{acordo.pasta}}</td>
                                                  <td class="text-center">{{acordo.numeroProcesso}}
                                                    <a class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true"
                                                        href="{{raiz}}/processos-judiciais/detalhes/{{acordo.idProcesso}}" target="_blank"></a></td>
                                                  <td class="text-center">{{acordo.razao}}</td>
                                                  <td class="text-center">{{acordo.devedor}}</td>
                                                  <td class="text-center">{{acordo.situacaoAcordo}}</td>
                                                  <td class="text-center">{{acordo.tipoAcordoDisplay}}</td>
                                                  <td class="text-center">{{acordo.valorPrincipal}}</td>
                                                  <td class="text-center">{{acordo.valorPago}}</td>
                                                  <td class="text-center">{{acordo.saldo}}</td>
                                                  <td class="text-center">
                                                      <button type="button" class="btn btn-primary btn-sm" (click)="abrirModalAcordo(acordo)">
                                                          <i class="fa fa-plus-circle"></i> Detalhes
                                                      </button>
                                                  </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div class="col-12 body-tabs" [ngbNavOutlet]="porAcordoTabs"></div>   
                </ng-template>
            </li>
            <li ngbNavItem [ngbNavItem]="2" (click)="carregarPorParcela()">
                <a ngbNavLink>Por Parcela</a>
                <ng-template ngbNavContent>
                    <ul ngbNav #porParcelaTabs="ngbNav" class="nav-tabs">
                        <li ngbNavItem [ngbNavItem]="1">
                            <a ngbNavLink>Pendentes</a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="text-center mt-5"
                                        *ngIf="!loadAcordosEmAberto && acordosParcelasEmAbertoPorParcela.length == 0">
                                        <h3>Não existem acordos pendentes</h3>
                                    </div>
                                    <div class="table-responsive"
                                        *ngIf="!loadAcordosEmAberto && acordosParcelasEmAbertoPorParcela.length > 0">
                                        <table class="table table-striped table-bordered card-table">
                                            <thead>
                                                <tr>
                                                    <th class="text-nowrap cursor-pointer" (click)="setSortPendentesPorParcela('idExterno')">
                                                        Código Acordo<i class="ion text-muted ml-2"
                                                            *ngIf="sortByPendentes =='idExterno'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap cursor-pointer" (click)="setSortPendentesPorParcela('pasta')">
                                                        Pasta<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='pasta'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentesPorParcela('numeroProcesso')">
                                                        Número Processo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='numeroProcesso'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentesPorParcela('razao')">
                                                        Cliente<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='razao'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentesPorParcela('devedor')">
                                                        Envolvido<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='devedor'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentesPorParcela('situacaoAcordo')">
                                                        Situação do Acordo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='situacaoAcordo'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentesPorParcela('tipoAcordoDisplay')">
                                                        Tipo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='tipoAcordoDisplay'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentesPorParcela('dataVencimento')">
                                                        Vencimento<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='dataVencimento'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentesPorParcela('numeroParcela')">
                                                        Parcela<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='numeroParcela'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>                                                    
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentesPorParcela('valorPrincipal')">
                                                        Valor Principal<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>                                        
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentesPorParcela('valorPago')">
                                                        Valores Pagos<i class="ion text-muted ml-2"
                                                            *ngIf="sortByPendentes =='valorPago'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentesPorParcela('saldo')">
                                                      Saldo<i class="ion text-muted ml-2"
                                                        *ngIf="sortByPendentes =='saldo'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
            
                                                    <th class="text-center text-nowrap align-middle">Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let acordo of acordosParcelasEmAbertoPorParcela">
                                                    <td class="text-center">{{acordo.idExterno}}</td>
            
                                                    <td class="text-center">{{acordo.pasta}}</td>
                                                    <td class="text-center">{{acordo.numeroProcesso}}
                                                      <a class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true"
                                                          href="{{raiz}}/processos-judiciais/detalhes/{{acordo.idProcesso}}" target="_blank"></a></td>
                                                    <td class="text-center">{{acordo.razao}}</td>
                                                    <td class="text-center">{{acordo.devedor}}</td>
                                                    <td class="text-center">{{acordo.situacaoAcordo}}</td>
                                                    <td class="text-center">{{acordo.tipoAcordoDisplay}}</td>
                                                    <td class="text-center">{{acordo.dataVencimento | date: 'dd/MM/yy'}}</td>
                                                    <td class="text-center">{{formatarParcela(acordo)}}</td>                                            
                                                    <td class="text-center">{{acordo.valorPrincipal | currency: 'BRL'}}</td>                                        
                                                    <td class="text-center">{{acordo.valorPago | currency: 'BRL'}}</td>
                                                    <td class="text-center">{{acordo.saldo | currency: 'BRL'}}</td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-primary btn-sm" (click)="abrirModalAcordo(acordo)">
                                                            <i class="fa fa-plus-circle"></i> Detalhes
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem [ngbNavItem]="2">
                            <a ngbNavLink>Vencem Hoje</a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="text-center mt-5"
                                        *ngIf="!loadAcordosVencemHoje && acordosParcelasVencidasPorParcela.length == 0">
                                        <h3>Não existem acordos pendentes</h3>
                                    </div>
                                    <div class="table-responsive"
                                        *ngIf="!loadAcordosVencemHoje && acordosParcelasVencidasPorParcela.length > 0">
                                        <table class="table table-striped table-bordered card-table">
                                            <thead>
                                                <tr>
                                                    <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('idExterno')">
                                                        Código Acordo<i class="ion text-muted ml-2"
                                                            *ngIf="sortByPendentes =='idExterno'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('pasta')">
                                                        Pasta<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='pasta'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('numeroProcesso')">
                                                        Número Processo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='numeroProcesso'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('razao')">
                                                        Cliente<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='razao'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('devedor')">
                                                        Envolvido<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='devedor'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('situacaoAcordo')">
                                                        Situação do Acordo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='situacaoAcordo'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('tipoAcordoDisplay')">
                                                        Tipo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='tipoAcordoDisplay'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('dataVencimento')">
                                                        Vencimento<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('numeroParcela')">
                                                        Parcela<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('quantidadeParcelas')">
                                                        Qtd. Parcelas<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('valorPrincipal')">
                                                        Valor Principal<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>                                        
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('valorPago')">
                                                        Valores Pagos<i class="ion text-muted ml-2"
                                                            *ngIf="sortByPendentes =='valorPago'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('saldo')">
                                                      Saldo<i class="ion text-muted ml-2"
                                                        *ngIf="sortByPendentes =='saldo'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
            
                                                    <th class="text-center text-nowrap align-middle">Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let acordo of acordosParcelasVencidasPorParcela">
                                                    <td class="text-center">{{acordo.idExterno}}</td>
            
                                                    <td class="text-center">{{acordo.pasta}}</td>
                                                    <td class="text-center">{{acordo.numeroProcesso}}
                                                      <a class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true"
                                                          href="{{raiz}}/processos-judiciais/detalhes/{{acordo.idProcesso}}" target="_blank"></a></td>
                                                    <td class="text-center">{{acordo.razao}}</td>
                                                    <td class="text-center">{{acordo.devedor}}</td>
                                                    <td class="text-center">{{acordo.situacaoAcordo}}</td>
                                                    <td class="text-center">{{acordo.tipoAcordoDisplay}}</td>
                                                    <td class="text-center">{{acordo.dataVencimento | date: 'dd/MM/yy'}}</td>
                                                    <td class="text-center">{{acordo.numeroParcela}}</td>
                                                    <td class="text-center">{{acordo.quantidadeParcelas}}</td>
                                                    <td class="text-center">{{acordo.valorPrincipal | currency: 'BRL'}}</td>                                        
                                                    <td class="text-center">{{acordo.valorPago | currency: 'BRL'}}</td>
                                                    <td class="text-center">{{acordo.saldo | currency: 'BRL'}}</td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-primary btn-sm" (click)="abrirModalAcordo(acordo)">
                                                            <i class="fa fa-plus-circle"></i> Detalhes
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem [ngbNavItem]="3">
                            <a ngbNavLink>Vencidas</a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="text-center mt-5"
                                        *ngIf="!loadAcordosVencidas && acordosParcelasVencidasPorParcela.length == 0">
                                        <h3>Não existem acordos pendentes</h3>
                                    </div>
                                    <div class="table-responsive"
                                        *ngIf="!loadAcordosVencidas && acordosParcelasVencidasPorParcela.length > 0">
                                        <table class="table table-striped table-bordered card-table">
                                            <thead>
                                                <tr>
                                                    <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('idExterno')">
                                                        Código Acordo<i class="ion text-muted ml-2"
                                                            *ngIf="sortByPendentes =='idExterno'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('pasta')">
                                                        Pasta<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='pasta'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('numeroProcesso')">
                                                        Número Processo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='numeroProcesso'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('razao')">
                                                        Cliente<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='razao'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('devedor')">
                                                        Envolvido<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='devedor'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('situacaoAcordo')">
                                                        Situação do Acordo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='situacaoAcordo'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('tipoAcordoDisplay')">
                                                        Tipo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='tipoAcordoDisplay'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('dataVencimento')">
                                                        Vencimento<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('numeroParcela')">
                                                        Parcela<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('quantidadeParcelas')">
                                                        Qtd. Parcelas<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('valorPrincipal')">
                                                        Valor Principal<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>                                        
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('valorPago')">
                                                        Valores Pagos<i class="ion text-muted ml-2"
                                                            *ngIf="sortByPendentes =='valorPago'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('saldo')">
                                                      Saldo<i class="ion text-muted ml-2"
                                                        *ngIf="sortByPendentes =='saldo'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
            
                                                    <th class="text-center text-nowrap align-middle">Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let acordo of acordosParcelasVencidasPorParcela">
                                                    <td class="text-center">{{acordo.idExterno}}</td>
            
                                                    <td class="text-center">{{acordo.pasta}}</td>
                                                    <td class="text-center">{{acordo.numeroProcesso}}
                                                      <a class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true"
                                                          href="{{raiz}}/processos-judiciais/detalhes/{{acordo.idProcesso}}" target="_blank"></a></td>
                                                    <td class="text-center">{{acordo.razao}}</td>
                                                    <td class="text-center">{{acordo.devedor}}</td>
                                                    <td class="text-center">{{acordo.situacaoAcordo}}</td>
                                                    <td class="text-center">{{acordo.tipoAcordoDisplay}}</td>
                                                    <td class="text-center">{{acordo.dataVencimento | date: 'dd/MM/yy'}}</td>
                                                    <td class="text-center">{{acordo.numeroParcela}}</td>
                                                    <td class="text-center">{{acordo.quantidadeParcelas}}</td>
                                                    <td class="text-center">{{acordo.valorPrincipal | currency: 'BRL'}}</td>                                        
                                                    <td class="text-center">{{acordo.valorPago | currency: 'BRL'}}</td>
                                                    <td class="text-center">{{acordo.saldo | currency: 'BRL'}}</td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-primary btn-sm" (click)="abrirModalAcordo(acordo)">
                                                            <i class="fa fa-plus-circle"></i> Detalhes
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div class="col-12 body-tabs" [ngbNavOutlet]="porParcelaTabs"></div>  
                </ng-template>
            </li>
        </ul>
        <div class="col-12 body-tabs" [ngbNavOutlet]="acordosTabsNova"></div>
    </div>
</div>

<!--<div class="row">
    <div class="col-12">
        <ul ngbNav #acordosTabs="ngbNav" class="nav-tabs">
            <li ngbNavItem [ngbNavItem]="1">
                <a ngbNavLink>Pendentes</a>
                <ng-template ngbNavContent>
                    <ul ngbNav #acordosTabsPendentes="ngbNav" class="nav-tabs">
                        <li ngbNavItem [ngbNavItem]="1">
                            <a ngbNavLink>Por Acordo

                                <div style="margin-left: 10px;" *ngIf="loadAcordosEmAberto"
                                    class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="text-center mt-5"
                                        *ngIf="!loadAcordosEmAberto && acordosParcelasEmAberto.length == 0">
                                        <h3>Não existem acordos pendentes</h3>
                                    </div>
                                    <div class="table-responsive"
                                        *ngIf="!loadAcordosEmAberto && acordosParcelasEmAberto.length > 0">
                                        <table class="table table-striped table-bordered card-table">
                                            <thead>
                                                <tr>
                                                    <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('idExterno')">
                                                        Código Acordo<i class="ion text-muted ml-2"
                                                            *ngIf="sortByPendentes =='idExterno'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('pasta')">
                                                        Pasta<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='pasta'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('numeroProcesso')">
                                                        Número Processo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='numeroProcesso'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('razao')">
                                                        Cliente<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='razao'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('devedor')">
                                                        Envolvido<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='devedor'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('situacaoAcordo')">
                                                        Situação do Acordo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='situacaoAcordo'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('tipoAcordoDisplay')">
                                                        Tipo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='tipoAcordoDisplay'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('valorPrincipal')">
                                                        Valor Principal<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>                                        
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('valorPago')">
                                                        Valores Pagos<i class="ion text-muted ml-2"
                                                            *ngIf="sortByPendentes =='valorPago'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('saldo')">
                                                      Saldo<i class="ion text-muted ml-2"
                                                        *ngIf="sortByPendentes =='saldo'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
            
                                                    <th class="text-center text-nowrap align-middle">Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let acordo of acordosParcelasEmAberto">
                                                    <td class="text-center">{{acordo.idExterno}}</td>
            
                                                    <td class="text-center">{{acordo.pasta}}</td>
                                                    <td class="text-center">{{acordo.numeroProcesso}}
                                                      <a class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true"
                                                          href="{{raiz}}/processos-judiciais/detalhes/{{acordo.idProcesso}}" target="_blank"></a></td>
                                                    <td class="text-center">{{acordo.razao}}</td>
                                                    <td class="text-center">{{acordo.devedor}}</td>
                                                    <td class="text-center">{{acordo.situacaoAcordo}}</td>
                                                    <td class="text-center">{{acordo.tipoAcordoDisplay}}</td>
                                                    <td class="text-center">{{acordo.valorPrincipal | currency: 'BRL'}}</td>                                        
                                                    <td class="text-center">{{acordo.valorPago | currency: 'BRL'}}</td>
                                                    <td class="text-center">{{acordo.saldo | currency: 'BRL'}}</td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-primary btn-sm" (click)="abrirModalAcordo(acordo)">
                                                            <i class="fa fa-plus-circle"></i> Detalhes
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem [ngbNavItem]="2" (click)="carrgarPorParcelaEmAbero()">                            
                            <a ngbNavLink>Por Parcela

                                <div style="margin-left: 10px;" *ngIf="loadAcordosEmAberto"
                                    class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="text-center mt-5"
                                        *ngIf="!loadAcordosEmAberto && acordosParcelasEmAberto.length == 0">
                                        <h3>Não existem acordos pendentes</h3>
                                    </div>
                                    <div class="table-responsive"
                                        *ngIf="!loadAcordosEmAberto && acordosParcelasEmAberto.length > 0">
                                        <table class="table table-striped table-bordered card-table">
                                            <thead>
                                                <tr>
                                                    <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('idExterno')">
                                                        Código Acordo<i class="ion text-muted ml-2"
                                                            *ngIf="sortByPendentes =='idExterno'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('pasta')">
                                                        Pasta<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='pasta'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('numeroProcesso')">
                                                        Número Processo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='numeroProcesso'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('razao')">
                                                        Cliente<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='razao'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('devedor')">
                                                        Envolvido<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='devedor'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('situacaoAcordo')">
                                                        Situação do Acordo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='situacaoAcordo'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('tipoAcordoDisplay')">
                                                        Tipo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='tipoAcordoDisplay'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('dataVencimento')">
                                                        Vencimento<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('numeroParcela')">
                                                        Parcela<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('quantidadeParcelas')">
                                                        Qtd. Parcelas<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('valorPrincipal')">
                                                        Valor Principal<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>                                        
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('valorPago')">
                                                        Valores Pagos<i class="ion text-muted ml-2"
                                                            *ngIf="sortByPendentes =='valorPago'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('saldo')">
                                                      Saldo<i class="ion text-muted ml-2"
                                                        *ngIf="sortByPendentes =='saldo'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
            
                                                    <th class="text-center text-nowrap align-middle">Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let acordo of acordosParcelasEmAbertoPorParcela">
                                                    <td class="text-center">{{acordo.idExterno}}</td>
            
                                                    <td class="text-center">{{acordo.pasta}}</td>
                                                    <td class="text-center">{{acordo.numeroProcesso}}
                                                      <a class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true"
                                                          href="{{raiz}}/processos-judiciais/detalhes/{{acordo.idProcesso}}" target="_blank"></a></td>
                                                    <td class="text-center">{{acordo.razao}}</td>
                                                    <td class="text-center">{{acordo.devedor}}</td>
                                                    <td class="text-center">{{acordo.situacaoAcordo}}</td>
                                                    <td class="text-center">{{acordo.tipoAcordoDisplay}}</td>
                                                    <td class="text-center">{{acordo.dataVencimento | date: 'dd/MM/yy'}}</td>
                                                    <td class="text-center">{{acordo.numeroParcela}}</td>
                                                    <td class="text-center">{{acordo.quantidadeParcelas}}</td>
                                                    <td class="text-center">{{acordo.valorPrincipal | currency: 'BRL'}}</td>                                        
                                                    <td class="text-center">{{acordo.valorPago | currency: 'BRL'}}</td>
                                                    <td class="text-center">{{acordo.saldo | currency: 'BRL'}}</td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-primary btn-sm" (click)="abrirModalAcordo(acordo)">
                                                            <i class="fa fa-plus-circle"></i> Detalhes
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div class="col-12 body-tabs" [ngbNavOutlet]="acordosTabsPendentes"></div>                    
                </ng-template>
            </li>
            <li ngbNavItem [ngbNavItem]="2">
                <a ngbNavLink>Vencem Hoje</a>
                <ng-template ngbNavContent>         
                    <ul ngbNav #acordosTabsVencidasHoje="ngbNav" class="nav-tabs">
                        <li ngbNavItem [ngbNavItem]="1">
                            <a ngbNavLink>Por Acordo

                                <div style="margin-left: 10px;" *ngIf="loadAcordosVencemHoje"
                                    class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="text-center mt-5"
                                        *ngIf="!loadAcordosVencemHoje && acordosParcelasVencemHoje.length == 0">
                                        <h3>Não existem acordos vencendo hoje</h3>
                                    </div>
                                    <div class="table-responsive"
                                        *ngIf="!loadAcordosVencemHoje && acordosParcelasVencemHoje.length > 0">
                                        <table class="table table-striped table-bordered card-table">
                                            <thead>
                                                <tr>
                                                  <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('idExterno')">
                                                    ID Acordo<i class="ion text-muted ml-2"
                                                        *ngIf="sortByPendentes =='idExterno'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('pasta')">
                                                    Pasta<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='pasta'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('numeroProcesso')">
                                                    Numero Processo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='numeroProcesso'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('razao')">
                                                    Cliente<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='razao'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('devedor')">
                                                    Envolvido<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='devedor'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('situacaoAcordo')">
                                                    Situação do Acordo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='situacaoAcordo'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('tipoAcordoDisplay')">
                                                    Tipo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='tipoAcordoDisplay'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('valorPrincipal')">
                                                    Valor Principal<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>                                    
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('valorPago')">
                                                    Valores Pagos<i class="ion text-muted ml-2"
                                                        *ngIf="sortByPendentes =='valorPago'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                (click)="setSortPendentes('saldo')">
                                                  Saldo<i class="ion text-muted ml-2"
                                                    *ngIf="sortByPendentes =='saldo'"
                                                    [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                            </th>
            
                                                <th class="text-center text-nowrap align-middle">Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let acordo of acordosParcelasVencemHoje">
                                                  <td class="text-center">{{acordo.idExterno}}</td>
            
                                                  <td class="text-center">{{acordo.pasta}}</td>
                                                  <td class="text-center">{{acordo.numeroProcesso}}
                                                    <a class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true"
                                                        href="{{raiz}}/processos-judiciais/detalhes/{{acordo.idProcesso}}" target="_blank"></a></td>
                                                  <td class="text-center">{{acordo.razao}}</td>
                                                  <td class="text-center">{{acordo.devedor}}</td>
                                                  <td class="text-center">{{acordo.situacaoAcordo}}</td>
                                                  <td class="text-center">{{acordo.tipoAcordoDisplay}}</td>
                                                  <td class="text-center">{{acordo.valorPrincipal}}</td>
                                                  <td class="text-center">{{acordo.valorPago}}</td>
                                                  <td class="text-center">{{acordo.saldo}}</td>
                                                  <td class="text-center">
                                                      <button type="button" class="btn btn-primary btn-sm" (click)="abrirModalAcordo(acordo)">
                                                          <i class="fa fa-plus-circle"></i> Detalhes
                                                      </button>
                                                  </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>           
                    <div class="col-12 body-tabs" [ngbNavOutlet]="acordosTabsVencidasHoje"></div>           
                </ng-template>
            </li>
            <li ngbNavItem [ngbNavItem]="3">
                <a ngbNavLink>Vencidas

                    <div style="margin-left: 10px;" *ngIf="loadAcordosVencidas"
                        class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>
                </a>
                <ng-template ngbNavContent>
                    <ul ngbNav #acordosTabsVencidas="ngbNav" class="nav-tabs">
                        <li ngbNavItem [ngbNavItem]="1">
                            <a ngbNavLink>Por Acordo

                                <div style="margin-left: 10px;" *ngIf="loadAcordosVencidas"
                                    class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="text-center mt-5"
                                        *ngIf="!loadAcordosVencidas && acordosParcelasVencidas.length == 0">
                                        <h3>Não existem acordos vencidos</h3>
                                    </div>
                                    <div class="table-responsive"
                                        *ngIf="!loadAcordosVencidas && acordosParcelasVencidas.length > 0">
                                        <table class="table table-striped table-bordered card-table">
                                            <thead>
                                                <tr>
                                                  <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('idExterno')">
                                                    ID Acordo<i class="ion text-muted ml-2"
                                                        *ngIf="sortByPendentes =='idExterno'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('pasta')">
                                                    Pasta<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='pasta'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('numeroProcesso')">
                                                    Numero Processo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='numeroProcesso'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('razao')">
                                                    Cliente<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='razao'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('devedor')">
                                                    Envolvido<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='devedor'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('situacaoAcordo')">
                                                    Situação do Acordo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='situacaoAcordo'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('tipoAcordoDisplay')">
                                                    Tipo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='tipoAcordoDisplay'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('valorPrincipal')">
                                                    Valor Principal<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>                                    
                                                <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('valorPago')">
                                                    Valores Pagos<i class="ion text-muted ml-2"
                                                        *ngIf="sortByPendentes =='valorPago'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer"
                                                (click)="setSortPendentes('saldo')">
                                                  Saldo<i class="ion text-muted ml-2"
                                                    *ngIf="sortByPendentes =='saldo'"
                                                    [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                            </th>
            
                                                <th class="text-center text-nowrap align-middle">Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let acordo of acordosParcelasVencidas">
                                                  <td class="text-center">{{acordo.idExterno}}</td>
            
                                                  <td class="text-center">{{acordo.pasta}}</td>
                                                  <td class="text-center">{{acordo.numeroProcesso}}
                                                    <a class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true"
                                                        href="{{raiz}}/processos-judiciais/detalhes/{{acordo.idProcesso}}" target="_blank"></a></td>
                                                  <td class="text-center">{{acordo.razao}}</td>
                                                  <td class="text-center">{{acordo.devedor}}</td>
                                                  <td class="text-center">{{acordo.situacaoAcordo}}</td>
                                                  <td class="text-center">{{acordo.tipoAcordoDisplay}}</td>
                                                  <td class="text-center">{{acordo.valorPrincipal}}</td>
                                                  <td class="text-center">{{acordo.valorPago}}</td>
                                                  <td class="text-center">{{acordo.saldo}}</td>
                                                  <td class="text-center">
                                                      <button type="button" class="btn btn-primary btn-sm" (click)="abrirModalAcordo(acordo)">
                                                          <i class="fa fa-plus-circle"></i> Detalhes
                                                      </button>
                                                  </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>

                        </li>
                        <li ngbNavItem [ngbNavItem]="2" (click)="carrgarPorParcelaVenidas()">                            
                            <a ngbNavLink>Por Parcela

                                <div style="margin-left: 10px;" *ngIf="loadAcordosVencidas"
                                    class="spinner-border ml-auto spinner-border-sm" role="status" aria-hidden="true"></div>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="text-center mt-5"
                                        *ngIf="!loadAcordosVencidas && acordosParcelasVencidasPorParcela.length == 0">
                                        <h3>Não existem acordos pendentes</h3>
                                    </div>
                                    <div class="table-responsive"
                                        *ngIf="!loadAcordosVencidas && acordosParcelasVencidasPorParcela.length > 0">
                                        <table class="table table-striped table-bordered card-table">
                                            <thead>
                                                <tr>
                                                    <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('idExterno')">
                                                        Código Acordo<i class="ion text-muted ml-2"
                                                            *ngIf="sortByPendentes =='idExterno'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap cursor-pointer" (click)="setSortPendentes('pasta')">
                                                        Pasta<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='pasta'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('numeroProcesso')">
                                                        Número Processo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='numeroProcesso'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('razao')">
                                                        Cliente<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='razao'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('devedor')">
                                                        Envolvido<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='devedor'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('situacaoAcordo')">
                                                        Situação do Acordo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='situacaoAcordo'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('tipoAcordoDisplay')">
                                                        Tipo<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='tipoAcordoDisplay'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('dataVencimento')">
                                                        Vencimento<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('numeroParcela')">
                                                        Parcela<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('quantidadeParcelas')">
                                                        Qtd. Parcelas<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('valorPrincipal')">
                                                        Valor Principal<i class="ion text-muted ml-2" *ngIf="sortByPendentes =='valorPrincipal'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>                                        
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                        (click)="setSortPendentes('valorPago')">
                                                        Valores Pagos<i class="ion text-muted ml-2"
                                                            *ngIf="sortByPendentes =='valorPago'"
                                                            [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                    </th>
                                                    <th class="text-nowrap text-center cursor-pointer"
                                                    (click)="setSortPendentes('saldo')">
                                                      Saldo<i class="ion text-muted ml-2"
                                                        *ngIf="sortByPendentes =='saldo'"
                                                        [ngClass]="'ion-md-arrow-' + (sortDescPendentes ? 'down' : 'up')"></i>
                                                </th>
            
                                                    <th class="text-center text-nowrap align-middle">Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let acordo of acordosParcelasVencidasPorParcela">
                                                    <td class="text-center">{{acordo.idExterno}}</td>
            
                                                    <td class="text-center">{{acordo.pasta}}</td>
                                                    <td class="text-center">{{acordo.numeroProcesso}}
                                                      <a class="fas fa-external-link-square-alt color-info p-0 ml-2" aria-hidden="true"
                                                          href="{{raiz}}/processos-judiciais/detalhes/{{acordo.idProcesso}}" target="_blank"></a></td>
                                                    <td class="text-center">{{acordo.razao}}</td>
                                                    <td class="text-center">{{acordo.devedor}}</td>
                                                    <td class="text-center">{{acordo.situacaoAcordo}}</td>
                                                    <td class="text-center">{{acordo.tipoAcordoDisplay}}</td>
                                                    <td class="text-center">{{acordo.dataVencimento | date: 'dd/MM/yy'}}</td>
                                                    <td class="text-center">{{acordo.numeroParcela}}</td>
                                                    <td class="text-center">{{acordo.quantidadeParcelas}}</td>
                                                    <td class="text-center">{{acordo.valorPrincipal | currency: 'BRL'}}</td>                                        
                                                    <td class="text-center">{{acordo.valorPago | currency: 'BRL'}}</td>
                                                    <td class="text-center">{{acordo.saldo | currency: 'BRL'}}</td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-primary btn-sm" (click)="abrirModalAcordo(acordo)">
                                                            <i class="fa fa-plus-circle"></i> Detalhes
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div class="col-12 body-tabs" [ngbNavOutlet]="acordosTabsVencidas"></div>                           
                </ng-template>
            </li>
        </ul>
        <div class="col-12 body-tabs" [ngbNavOutlet]="acordosTabs"></div>
    </div>
</div>-->
