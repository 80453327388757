<div class="modal-header">

    <h5 class="modal-title">
        Parcelas do Acordo Minuta Principal e Honorarios
    </h5>
    <button type="button" class="close" (click)="dismiss()">&times;</button>
</div>
<div class="modal-body">
    <ul ngbNav #acordoDetalhes="ngbNav" class="nav-tabs">
        <li ngbNavItem>
            <a ngbNavLink>Fluxo Pagamentos Acordo Principal</a>
            <ng-template ngbNavContent>
                <div class="row">
                    <div class="col-12 mt-3 text-center" *ngIf="loadingAcordo">
                        <i class="fas fa-spinner fa-pulse"></i> Carregando acordo ...
                    </div>
                    <div class="col-md-12" *ngIf="!loadingAcordo && acordo && acordo.dividas && acordo.dividas.length > 0">
                        <div>
                            <table class="table product-discounts-edit espacamento-tabela-dividas">
                                <thead>
                                    <tr>
                                        <th>Tipo Parcela</th>
                                        <th>Parcela</th>
                                        <th>Emissão</th>
                                        <th>Vencimento</th>
                                        <th>Valor Juros</th>
                                        <!--th>Valor Multa</th-->
                                        <th>Índice de correção</th>
                                        <th>Valor cobrança</th>
                                        <th>Valor Pago</th>
                                        <th>Data Pgto.</th>
                                        <th>Saldo</th>
                                        <th>Ação</th> <!--removido por enquanto-->
                                        <!-- <th>Tipo</th> -->
                                    </tr>
                                </thead>
                                <tbody style="font-size: 8pt">
                                    <tr *ngFor="let parcela of acordo.dividas">
                                        <td>{{parcela.tipoParcela}}</td>
                                        <td>{{parcela.parcela}}/{{acordo.parcelas}}</td>
                                        <td>{{parcela.dataEmissao | date: 'dd/MM/yyyy'}}</td>
                                        <td>{{parcela.dataVencimento | date: 'dd/MM/yyyy'}}</td>
                                        <td>{{acordo.percentualJuros}}</td>
                                        <!--td>{{acordo.percentualMulta}}</td-->
                                        <td>{{acordo.percentualIndiceCorrecao ? acordo.percentualIndiceCorrecao : 0 }}</td>
                                        <td>{{parcela.valorAberto | currency: 'BRL'}}</td>
                                        <td>{{(parcela.valorPago ? parcela.valorPago : 0) | currency: 'BRL'}}</td>
                                        <td>{{parcela.dataPagamento | date: 'dd/MM/yyyy'}}</td>
                                        <td>{{parcela.valorAberto - (parcela.valorPago ? parcela.valorPago : 0) | currency: 'BRL'}}</td>

                                        <td>
                                            <button *ngIf="!parcela.valorPago" type="button" class="btn btn-primary btn-sm" (click)="abrirModalPagamentoAcordo(acordo, parcela)">
                                                <i class="fas fa-file-invoice-dollar"></i> Lançar Pagamento
                                            </button>
                                            <button *ngIf="parcela.arquivoId" type="button" class="btn btn-warning btn-sm" (click)="downloadAnexo(parcela)">
                                                <i class="fas fa-paperclip"></i>
                                            </button>
                                        </td>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-12 mt-1 mb-2">
                            <div class="float-right">
                                Índice: {{ acordo.indiceNome ? acordo.indiceNome : " - " }}
                            </div>
                        </div>
                        <div class="col-12 mt-5 mb-2">                            
                            <div class="float-right">
                                <b>Total a pagar: {{(acordo.valorTotal) | currency: 'BRL'}}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </li>
        <li ngbNavItem>
            <a ngbNavLink>Ocorrências de Acordo Principal</a>
            <ng-template ngbNavContent>
                <app-historico-acordo-judicial [acordoId]="acordoId"></app-historico-acordo-judicial>
            </ng-template>
        </li>
        <li ngbNavItem *ngIf="false">
          <a ngbNavLink>Honorários</a>
          <ng-template ngbNavContent>
              <div class="row">
                  <div class="col-12 mt-3 text-center" *ngIf="loadingAcordoHonorario">
                      <i class="fas fa-spinner fa-pulse"></i> Carregando Acordo Honorário...
                  </div>
                  <div class="col-md-12" *ngIf="!loadingAcordoHonorario && acordoHonorario && acordoHonorario.dividas && acordoHonorario.dividas.length > 0">
                      <div>
                          <table class="table product-discounts-edit espacamento-tabela-dividas">
                              <thead>
                                  <tr>
                                      <th>Tipo Parcela</th>
                                      <th>Parcela</th>
                                      <th>Emissão</th>
                                      <th>Vencimento</th>
                                      <th>Valor Juros</th>
                                      <th>Valor Multa</th>
                                      <th>Valor Índice Correção</th>
                                      <th>Valor Pago</th>
                                      <th>Saldo</th>
                                      <th>Valor cobrança</th>
                                      <th>Ação</th>
                                      <!-- <th>Tipo</th> -->
                                  </tr>
                              </thead>
                              <tbody style="font-size: 8pt">
                                  <tr *ngFor="let parcela of acordoHonorario.dividas">
                                      <td>{{parcela.tipoParcela}}</td>
                                      <td>{{parcela.parcela}}/{{acordo.parcelas}}</td>
                                      <td>{{parcela.dataEmissao | date: 'dd/MM/yyyy'}}</td>
                                      <td>{{parcela.dataVencimento | date: 'dd/MM/yyyy'}}</td>
                                      <td>{{acordoHonorario.percentualJuros}}</td>
                                      <td>{{acordoHonorario.percentualMulta}}</td>
                                      <td>{{acordoHonorario.percentualIndiceCorrecao ? acordoHonorario.percentualIndiceCorrecao : 0 }}</td>
                                      <td>{{parcela.valorPago ? parcela.valorPago : 0 }}</td>
                                      <td>{{parcela.valorAberto - (parcela.valorPago ? parcela.valorPago : 0)}}</td>
                                      <td>{{parcela.valorAberto | currency: 'BRL'}}</td>
                                      <td>
                                          <button type="button" class="btn btn-primary btn-sm" (click)="abrirModalPagamentoAcordo(parcela)">
                                              <i class="fa fa-plus-circle"></i> Pagamento
                                          </button>
                                      </td>
                              </tbody>
                          </table>
                      </div>

                      <div class="col-12 mt-1 mb-2">
                          <div class="float-right">
                              <b>Total a pagar: {{(acordo.valorTotal) | currency: 'BRL'}}</b>
                          </div>
                      </div>
                  </div>
              </div>
          </ng-template>
      </li>
      <li ngbNavItem *ngIf="false">
          <a ngbNavLink>Histórico Honorários</a>
          <ng-template ngbNavContent>
              <app-historico-acordo-judicial [acordoId]="acordoIdHonorario"></app-historico-acordo-judicial>
          </ng-template>
      </li>
    </ul>
    <div class="row body-card">
        <div class="col-12" [ngbNavOutlet]="acordoDetalhes"></div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="close()">Fechar</button>
</div>
