<div class="mt-3" *ngIf="honorarios != undefined && honorarios.length > 0" >    
    <div class="row" *ngFor="let honorario of honorarios">        
        <div class="mt-2 col-12">
            <hr />                        
        </div>
        <div class="mt-2 col-12">
            <div class="text-right text-nowrap align-right">
                <button class="btn btn-xs icon-btn btn-warning" type="button" (click)="editar(honorario)">
                    <span class="far fa-edit"></span>
                </button>
                <button class="btn btn-xs icon-btn btn-danger" type="button" (click)="remover(honorario)">
                    <span class="far fa-trash-alt"></span>
                </button>
            </div>
        </div>
        <div class="col-6 mt-2">
            Código: {{honorario.idExterno}}
        </div>
        <div class="col-6 mt-2">
            Faturado: {{honorario.faturado ? 'Sim':'Não'}}
        </div>
        <div class="col-6 mt-2">
            Data Vencimento: {{honorario.vencimento | date:"dd/MM/yyyy"}}
        </div>
        <div class="col-6 mt-2">
            Valor Honorário: {{honorario.valorHonorario | currency : "BRL"}}
        </div>    
        <div class="col-6 mt-2" *ngIf="tipoHonorario == 3">
            Valor Recuperado: {{honorario.valorRecuperado | currency : "BRL"}}
        </div>
        <div class="col-6 mt-2" *ngIf="tipoHonorario == 4">
            Duração: {{honorario.duracao}}
        </div>
        <div class="col-6 mt-2" *ngIf="tipoHonorario == 4">
            Valor Hora: {{honorario.valorHoraInformado | currency : "BRL" }}
        </div>
        <div class="col-6 mt-2" *ngIf="tipoHonorario == 3">
            Valor Recuperado: {{honorario.percentualHonorario | number }}%
        </div>
        <div class="col-12 mt-2">
            Descrição: {{honorario.descricao}}
        </div>
        <div class="col-12 mt-2" *ngIf="honorario.arquivos != undefined && honorario.arquivos.length">            
            Anexos: <br />
            <ng-container *ngFor="let arquivo of honorario.arquivos">                        
                <div class="btn btn-primary" [ngbTooltip]="arquivo.nomeArquivo"
                    (click)="handleDownloadArquivo(arquivo)">
                    {{arquivo.arquivoNome | uppercase}}
                    <i class="btn btn-xs icon-btn btn-primary"><span
                            class="fas fa-download"></span></i>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<!-- <table class="table table-striped table-bordered card-table" *ngIf="honorarios != undefined && honorarios.length > 0">
    <thead *>
        <tr>                        
            <th class="text-nowrap text-center align-middle">
                Descrição
            </th>
            <th class="text-nowrap text-center align-middle">
                Valor
            </th>
            <th class="text-nowrap text-center align-middle">
                Faturado?
            </th>                        
            <th class="text-center text-nowrap align-middle">
                Anexos
            </th>
        </tr>
    </thead>
    <tbody>
        <tr class="tabela" *ngFor="let honorario of honorarios">                        
            <td>
                {{honorario.descricao}}
            </td>
            <td>
                {{honorario.valorHonorario}}
            </td>
            <td>
                {{honorario.faturado ? 'Sim':'Não'}}
            </td>                                                
            <td class="text-center text-nowrap align-middle">
                <div class="anexo d-flex align-items-center">
                    <ng-container *ngFor="let arquivo of honorario.arquivos">                        
                        <div class="btn btn-primary" [ngbTooltip]="arquivo.nomeArquivo"
                            (click)="handleDownloadArquivo(arquivo)">
                            {{arquivo.arquivoNome | uppercase}}
                            <i class="btn btn-xs icon-btn btn-primary"><span
                                    class="fas fa-download"></span></i>
                        </div>
                    </ng-container>
                </div>
            </td>
        </tr>
    </tbody>
</table> -->