import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { HonorarioService } from 'src/app/services/honorario.service';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import Swal from 'sweetalert2';
import { ModalHonorarioConvencionadosComponent } from '../modal-honorario-convencionados/modal-honorario-convencionados.component';

@Component({
    selector: 'app-honorarios-listar-detalhes',
    templateUrl: './honorarios-listar-detalhes.component.html',
    styleUrls: ['./honorarios-listar-detalhes.component.scss'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class HonorariosListarDetalhesComponent implements OnInit {

    @Input() tipo: string;
    @Input() referenciaId: string;
    @Input() raiz: string;
    @Input() tipoHonorario: string;
    @Input() refresh: string = "";
    @Output() refreshChange = new EventEmitter<string>();
    @Output() refreshTagProcesso = new EventEmitter();

    public honorarios: any;

    private swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-padrao btn-lg mr-3',
            cancelButton: 'btn btn-default btn-lg'
        },
        buttonsStyling: false
    });

    constructor(
        public honorarioService: HonorarioService,
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private router: Router,
        private ngbModal: NgbModal,
    ) { }

    ngOnInit(): void {
        this.buscarHonorarios();
    }

    buscarHonorarios() {
        this.honorarioService.buscarTodos(this.tipoHonorario, this.tipo, this.referenciaId)
            .subscribe(res => {
                this.honorarios = res.data;
            })
    }

    ngOnChanges() {
        this.honorarios = null;
        this.buscarHonorarios();
    }

    remover(honorario) {
        
        this.swalWithBootstrapButtons.fire({
            text: 'Deseja excluir o honorário com o valor de ' + honorario.valorHonorario.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) + ' ?',
            confirmButtonColor: '#FFD950 !important;',
            showCloseButton: true,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.spinner.show();

                this.honorarioService.remover(honorario.id).subscribe(res => {                    
                    this.refreshChange.emit(new Date().toString());                    
                    this.spinner.hide();
                }, error => {
                    if (error && error.errors) {
                        this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                    }
                    else {
                        this.toastrService.error('Não foi possível atualizar o perfil!', 'Atenção', { timeOut: 10000 });
                    }
                    this.spinner.hide();
                });
            }
        });
    }

    editar(honorario) {
        if(honorario.tipoHonorario == 1){            
            this.router.navigate([`/${this.raiz}/honorario-por-valor-editar/${honorario.id}`]);
        }else{
            this.abrirModalConvenionados(honorario);
        }
    }

    abrirModalConvenionados(honorario) {        
        const modalRef = this.ngbModal.open(ModalHonorarioConvencionadosComponent, { size: 'md' });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.tipo = honorario.tipoReferencia;
        modalRef.componentInstance.referenciaId = honorario.referenciaId; 
        modalRef.componentInstance.honorario = honorario;
        modalRef.componentInstance.refreshTagProcesso = this.refreshTagProcesso;
        
        modalRef.result.then(result => {
            this.refreshChange.emit(new Date().toString());             
        });
    }
}
