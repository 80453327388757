import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AcordoJuridicoService } from 'src/app/services/acordo-juridico.service';
import { ModalParcelasComponent } from './modal-parcelas/modal-parcelas.component';

@Component({
  selector: 'app-gestao-acordos-judiciais',
  templateUrl: './gestao-acordos-judiciais.component.html',
  styleUrls: ['./gestao-acordos-judiciais.component.scss']
})
export class GestaoAcordosJudiciaisComponent implements OnInit {

    public loadAcordosEmAberto: boolean = true;
    public loadAcordosVencemHoje: boolean = true;
    public loadAcordosVencidas: boolean = true;
    public filtroOn: boolean = false;

    public acordosParcelasEmAberto: any =[];
    public acordosParcelasEmAbertoPorParcela: any =[];
    public acordosParcelasVencemHoje: any =[];
    public acordosParcelasVencidas: any =[];
    public acordosParcelasVencidasPorParcela: any =[];
    public raiz: string = '';
    public filtro: any = {
        codigoAcordo: <number | null> null,
        pasta:<string> '',
        numeroProcesso:<string>'',
        cliente:<Guid | null> null,
        envolvido:<Guid | null> null,
        minutaDeAcordo: "1"
    }

    //ordenação
    sortByPendentes: string = 'razao';
    sortDescPendentes: boolean = false;
    aba = 1;

    constructor(
        private acordoJuridicoService: AcordoJuridicoService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        public modalService: NgbModal,
        private router: Router,
    ) { }

    ngOnInit(): void {
      if (this.router.url.indexOf('/dashboard/') > -1) {
        this.raiz = '/dashboard'
      } else {
        this.raiz = '/operacionais'
      }
        this.carregarAcordos();
    }

    filtrar(){
        if(this.aba == 1){
            this.carregarAcordos();
        }else{
            this.carrgarPorParcelaEmAbero();
        }
    }

    limparFiltros(){
        this.filtro.codigoAcordo = null;
        this.filtro.pasta = '';
        this.filtro.numeroProcesso = '';
        this.filtro.cliente = null;
        this.filtro.envolvido = null;
        this.filtro.minutaDeAcordo = "1";

        this.carregarAcordos();
    }    

    carrgarPorParcelaEmAbero(){
        this.loadAcordosEmAberto = true;
        
        this.acordoJuridicoService.obterAcordosParcelasEmAbertoPorParcela(this.filtro)
            .subscribe(res=>{
                this.loadAcordosEmAberto = false;
                this.acordosParcelasEmAbertoPorParcela = res.data;                
                console.log(res.data);
            },err=>{
                this.toastrService.error('Não foi possível buscar acordos em aberto!', 'Atenção', { timeOut: 10000 });
                this.loadAcordosEmAberto = false;
            })
    }

    carrgarPorParcelaVencemHoje(){
        this.loadAcordosVencemHoje = true;
        
        this.acordoJuridicoService.obterAcordosParcelasVencemHojePorParcela(this.filtro)
            .subscribe(res=>{
                this.loadAcordosVencemHoje = false;
                this.acordosParcelasVencidasPorParcela = res.data;                
            },err=>{
                this.toastrService.error('Não foi possível buscar acordos vecem hoje!', 'Atenção', { timeOut: 10000 });
                this.loadAcordosVencemHoje = false;
            })
    }

    carrgarPorParcelaVenidas(){
        this.loadAcordosVencidas = true;
        
        this.acordoJuridicoService.obterAcordosParcelasVencidasPorParcela(this.filtro)
            .subscribe(res=>{
                this.loadAcordosVencidas = false;
                this.acordosParcelasVencidasPorParcela = res.data;                
            },err=>{
                this.toastrService.error('Não foi possível buscar acordos vencidos!', 'Atenção', { timeOut: 10000 });
                this.loadAcordosVencidas = false;
            })
    }

    carregarAcordos() {
        this.aba = 1;

        this.loadAcordosEmAberto = true;

        this.loadAcordosVencemHoje = true;

        this.loadAcordosVencidas = true;

        const obterAcordosParcelasEmAberto = this.acordoJuridicoService.obterAcordosParcelasEmAberto(this.filtro).toPromise();

        const obterAcordosParcelasVencemHoje = this.acordoJuridicoService.obterAcordosParcelasVencemHoje(this.filtro).toPromise();

        const obterAcordosParcelasVencidas = this.acordoJuridicoService.obterAcordosParcelasVencidas(this.filtro).toPromise();                

        Promise.all([obterAcordosParcelasEmAberto, obterAcordosParcelasVencemHoje, obterAcordosParcelasVencidas])
            .then(res=>{

                this.alimentarAcordosEmAberto(res[0].data);

                this.alimentarAcordosVencemHoje(res[1].data);

                this.alimentarAcordosVencidos(res[2].data);
            })
            .catch(()=> {
                this.toastrService.error('Não foi possível buscar acordos em aberto!', 'Atenção', { timeOut: 10000 });
            }).finally(()=>{
                this.loadAcordosEmAberto = false;

                this.loadAcordosVencemHoje = false;

                this.loadAcordosVencidas = false;
            });
    }

    carregarPorParcela(){
        this.aba = 2;

        this.carrgarPorParcelaEmAbero();

        //Falta o vence hoje!
        this.carregarPorParcela

        this.carrgarPorParcelaVenidas();
    }

    abrirFecharFiltro(){
        this.filtroOn = !this.filtroOn;
    }

    alimentarAcordosEmAberto(acordos: any[]){
        this.acordosParcelasEmAberto = acordos;
        console.log(acordos);
        this.sort(this.acordosParcelasEmAberto, this.sortByPendentes, this.sortDescPendentes);
    }

    alimentarAcordosVencemHoje(acordos: any[]){
        this.acordosParcelasVencemHoje = acordos;

        this.sort(this.acordosParcelasVencemHoje, this.sortByPendentes, this.sortDescPendentes);
    }

    alimentarAcordosVencidos(acordos: any[]){
        this.acordosParcelasVencidas = acordos;

        this.sort(this.acordosParcelasVencidas, this.sortByPendentes, this.sortDescPendentes);
    }

    setSortPendentes(key) {
        
        if (this.sortByPendentes !== key) {
            this.sortByPendentes = key;
            this.sortDescPendentes = false;
        } else {
            this.sortDescPendentes = !this.sortDescPendentes;
        }

        this.sort(this.acordosParcelasEmAberto, this.sortByPendentes, this.sortDescPendentes);
    }

    setSortPendentesPorParcela(key){
        if (this.sortByPendentes !== key) {
            this.sortByPendentes = key;
            this.sortDescPendentes = false;
        } else {
            this.sortDescPendentes = !this.sortDescPendentes;
        }

        this.sort(this.acordosParcelasEmAbertoPorParcela, this.sortByPendentes, this.sortDescPendentes);
    }

    abrirModalAcordo(acordo){
        const modalRef = this.modalService.open(ModalParcelasComponent, {
            size: "lg",
            backdrop : 'static',
            keyboard : false
        });
        console.log(acordo);
        modalRef.componentInstance.acordoId = acordo.id;
        modalRef.componentInstance.acordoIdHonorario = acordo.idHonorario;
        modalRef.componentInstance.modalRef = modalRef;

        modalRef.result.then((result) => {
            this.carregarAcordos();
        });
    }

    sort(data: any, sortBy: string, sortDesc: boolean) {

        data.sort((a: any, b: any) => {
            a = typeof (a[sortBy]) === 'string' ? a[sortBy].toUpperCase() : a[sortBy];
            b = typeof (b[sortBy]) === 'string' ? b[sortBy].toUpperCase() : b[sortBy];

            if (a < b) { return sortDesc ? 1 : -1; }
            if (a > b) { return sortDesc ? -1 : 1; }
            return 0;
        });
    }

    formatarParcela(acordo){
        const parcela = acordo.numeroParcela < 9 ? '0'+ acordo.numeroParcela : acordo.numeroParcela;
        const quantidadeParcelas = acordo.quantidadeParcelas < 9 ? '0'+ acordo.quantidadeParcelas : acordo.quantidadeParcelas;

        return `${parcela}/${quantidadeParcelas}`;
    }

}
